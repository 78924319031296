import styled from "styled-components";
import { AppMenuProps } from "./index";
import { Link } from "react-router-dom";
import { LogoutButton } from "./LogoutButton";

const MainNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  height: 100%;
`;

export const SideAppMenu = ({ items }: AppMenuProps) => {
  return (
    <MainNav>
      <h2 style={{ textAlign: "center" }}>Zone</h2>
      {items!.map((i, index) => (
        <Link key={index} to={i.path}>
          {i.title}
        </Link>
      ))}
      <LogoutButton style={{ marginTop: "auto" }} />
    </MainNav>
  );
};
