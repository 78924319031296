import moment from "moment";
import { DateString } from "../../../utils/date-utils";

export const buildWeekString = (week: DateString): string => {
  const startOfThisWeek = moment().startOf("isoWeek");
  const startOfNextWeek = moment(startOfThisWeek).add(1, "week");

  const startOfWeek = moment(week);

  if (startOfWeek.isSame(startOfThisWeek)) {
    return "This Week";
  }
  if (startOfWeek.isSame(startOfNextWeek)) {
    return "Next Week";
  }

  return `Week ${startOfWeek.format("WW")}`;
};
