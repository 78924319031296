import { signInWithGoogle } from "./business/auth";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Card } from "primereact/card";
import { Button } from "primereact/button";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background: #f8f8f9;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 250px;
`;

export const Login = () => {
  const navigate = useNavigate();

  const login = useCallback(() => {
    signInWithGoogle().then(() => navigate("/"));
  }, [navigate]);

  return (
    <Root>
      <Card>
        <Content>
          <h1>Zone</h1>
          <p style={{ whiteSpace: "pre-line", marginBottom: "48px" }}>
            {"Hello!\nLet's get you signed in."}
          </p>
          <Button onClick={login}>Sign In with Google</Button>
        </Content>
      </Card>
    </Root>
  );
};
