import { z } from "zod";
import { FirestoreWorkspaceEntitySource } from "../../../business";
import { getCurrentWorkspace } from "../../../business/auth";
import { uuid } from "../../../business/data/utils";
import { toDateString, toTimeString } from "../../../utils/date-utils";
import { SessionFieldsSchema } from "./fields";

export const Disciplines = z.enum(["swim", "bike", "run", "strength", "other"]);
export type Discipline = z.infer<typeof Disciplines>;
export const SessionStatusSchema = z.enum([
  "draft",
  "planned",
  "completed",
  "missed",
]);
export type SessionStatus = z.infer<typeof SessionStatusSchema>;

export const TrainingSessionSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  title: z.string().default(""),
  discipline: Disciplines,
  status: SessionStatusSchema.default("draft"),
  date: z.string().default(() => toDateString(new Date())),
  startTime: z
    .string()
    .nullable()
    .default(() => toTimeString(new Date())),
  priority: z.number().min(1).max(2).nullish(),
  planned: SessionFieldsSchema.default({}),
  data: SessionFieldsSchema.default({}),
  progressionId: z.string().nullish(),
});
export type TrainingSession = z.infer<typeof TrainingSessionSchema>;

export const TrainingSessionSource =
  new FirestoreWorkspaceEntitySource<TrainingSession>(
    "sessions",
    TrainingSessionSchema,
    getCurrentWorkspace,
  );
