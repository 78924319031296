import { Button, ButtonProps } from "primereact/button";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

const Float = styled(Button)`
  position: fixed;
  bottom: 16px;
  right: 16px;
`;

export const FloatButton = ({ style, ...props }: ButtonProps) => {
  return (
    <Float style={{ ...style, width: isMobile ? "100%" : "465px" }} {...props}>
      {props.children}
    </Float>
  );
};
