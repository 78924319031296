import { SessionStatus, SessionStatusSchema } from "../data/sessions";

import { CSSProperties } from "react";
import { Tag } from "primereact/tag";
import styled from "styled-components";

type StatusTagProps = {
  status: SessionStatus;
  style?: CSSProperties;
};

const StyledTag = styled(Tag)`
  span {
    font-size: 11px;
  }
`;

export const StatusTag = ({ status, style }: StatusTagProps) => {
  const statusMap: { [index: string]: CSSProperties } = {
    [SessionStatusSchema.Values.draft]: {
      color: "var(--text-color-secondary)",
      border: "1px solid var(--text-color-secondary)",
    },
    [SessionStatusSchema.Values.planned]: {
      color: "var(--blue-500)",
      border: "1px solid var(--blue-500)",
    },
    [SessionStatusSchema.Values.completed]: {
      background: "var(--green-500)",
    },
    [SessionStatusSchema.Values.missed]: {
      background: "var(--red-200)",
    },
  };

  return (
    <StyledTag
      style={{
        background: "transparent",
        border: "1px solid",
        ...statusMap[status],
        ...style,
      }}
    >
      {status}
    </StyledTag>
  );
};
