import { FirestoreWorkspaceEntitySource } from "../../../business";
import { getCurrentWorkspace } from "../../../business/auth";

import { z } from "zod";
import { uuid } from "../../../business/data/utils";
import moment from "moment";
import { DateStringSchema, toDateString } from "../../../utils/date-utils";
import { TrainingSession } from "../../schedule/data/sessions";
import { sumBy } from "lodash";
import { toHours } from "../../../utils/time-utils";

export const SeasonSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  title: z.string().default(""),
  start: DateStringSchema,
  end: DateStringSchema,
});
export type Season = z.infer<typeof SeasonSchema>;

export const SeasonStore = new FirestoreWorkspaceEntitySource<Season>(
  "seasons",
  SeasonSchema,
  getCurrentWorkspace,
);

export const SeasonWeekType = z.enum(["plan", "actual"]);

export const SeasonWeekSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  seasonId: z.string().uuid(),
  index: z.number(),
  start: DateStringSchema,
  end: DateStringSchema,
  month: z.string(),
  type: SeasonWeekType.default("plan"),
  block: z.string().nullish(),
  title: z.string().nullish(),
  totalCount: z.number().nullish(),
  swimCount: z.number().nullish(),
  bikeCount: z.number().nullish(),
  runCount: z.number().nullish(),
  strengthCount: z.number().nullish(),
  otherCount: z.number().nullish(),
});
export type SeasonWeek = z.infer<typeof SeasonWeekSchema>;

export const SeasonWeekStore = new FirestoreWorkspaceEntitySource<SeasonWeek>(
  "seasonWeeks",
  SeasonWeekSchema,
  getCurrentWorkspace,
);

export const findActiveSeason = async (
  seasons?: Season[],
): Promise<Season | null> => {
  const allSeasons =
    seasons ?? (await SeasonStore.list([{ field: "start", direction: "asc" }]));
  const activeSeasons = allSeasons.filter((s) =>
    moment().isBetween(s.start, s.end, "day", "[]"),
  );

  return activeSeasons.length > 0 ? activeSeasons[0] : null;
};

export const generateCompletedWeeks = (
  season: Season,
  sessions: TrainingSession[],
): SeasonWeek[] => {
  const weeks = generateSeasonWeeks(season);
  const pastWeeks = weeks.filter((w) =>
    moment(w.end).isSameOrBefore(moment(), "isoWeek"),
  );

  return pastWeeks.map((week) => {
    const weekSessions = sessions.filter((s) =>
      moment(s.date).isBetween(week.start, week.end, "day", "[]"),
    );

    return {
      ...week,
      type: SeasonWeekType.Values.actual,
      totalCount: sumBy(weekSessions, (s) =>
        toHours(s.status === "completed" ? s.data.duration ?? 0 : 0),
      ),
    };
  });
};

export const generateSeasonWeeks = (season: Season) => {
  const currentStart = moment(season.start).startOf("isoWeek");
  const currentEnd = moment(season.start).endOf("isoWeek");

  const end = moment(season.end).endOf("isoWeek");

  const weeks = [];

  while (currentEnd.isSameOrBefore(end, "day")) {
    weeks.push({
      id: uuid(),
      index: weeks.length + 1,
      seasonId: season.id,
      type: SeasonWeekType.Values.plan,
      month: currentStart.format("MMMM YYYY"),
      start: toDateString(currentStart.toDate()),
      end: toDateString(currentEnd.toDate()),
    });

    currentStart.add(1, "week");
    currentEnd.add(1, "week");
  }

  return weeks;
};

export const saveSeasonWeeks = async (
  season: Season,
): Promise<SeasonWeek[]> => {
  const weeks = generateSeasonWeeks(season);

  for (const week of weeks) {
    await SeasonWeekStore.create(week);
  }

  return weeks;
};
