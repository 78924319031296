import { Skeleton } from "primereact/skeleton";
import styled from "styled-components";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const LoadingList = ({
  rows,
  rowHeight,
}: {
  rows?: number;
  rowHeight?: string;
}) => (
  <Root>
    {Array(rows ?? 5)
      .fill(null)
      .map((_, index) => (
        <Skeleton key={index} height={rowHeight ?? "30px"} />
      ))}
  </Root>
);
