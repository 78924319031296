import { Filters } from "../../../business/data/entity-source";
import { Chart } from "primereact/chart";
import { useEffect, useMemo, useState } from "react";
import {
  generateCompletedWeeks,
  Season,
  SeasonStore,
  SeasonWeek,
  SeasonWeekStore,
} from "../data/seasons";
import moment from "moment";
import { TrainingSessionSource } from "../../schedule/data/sessions";
import { isMobile } from "react-device-detect";
import { Panel } from "primereact/panel";

type SeasonOverviewProps = {
  seasonId: string;
};
export const SeasonOverview = ({ seasonId }: SeasonOverviewProps) => {
  const [season, setSeason] = useState<Season | undefined>();

  const [plannedWeeks, setPlannedWeeks] = useState<SeasonWeek[]>([]);
  const [actualWeeks, setActualWeeks] = useState<SeasonWeek[]>([]);

  useEffect(() => {
    SeasonStore.find(seasonId).then(setSeason);
  }, [seasonId]);

  useEffect(() => {
    return SeasonWeekStore.observe(
      (weeks) => setPlannedWeeks(weeks),
      [Filters.eq("seasonId", seasonId)],
      [{ field: "start", direction: "asc" }],
    );
  }, [seasonId]);

  useEffect(() => {
    if (season) {
      TrainingSessionSource.observe((sessions) =>
        setActualWeeks(generateCompletedWeeks(season, sessions)),
      );
    }
  }, [season]);

  const combinedWeeks = useMemo(() => {
    return plannedWeeks.map((week) => {
      const actualWeek = actualWeeks.find((w) => w.start === week.start);
      return actualWeek ?? week;
    });
  }, [plannedWeeks, actualWeeks]);

  const datasets = useMemo(() => {
    return [
      {
        data: combinedWeeks.map((week) => week.totalCount ?? 0),
        backgroundColor: combinedWeeks.map((week) => {
          if (moment(week.start).isSame(moment(), "week")) {
            return "#9bb1cc";
          }

          if (week.type === "actual") {
            return "#b0c6a0";
          }
          return "#ebebeb";
        }),
      },
    ];
  }, [combinedWeeks]);

  if (!season) return null;

  const options = {
    animation: false,
    scales: {
      y: {
        display: false,
        beginAtZero: true,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Panel
      header={`${season.title} - week ${actualWeeks.length}`}
      toggleable
      collapsed={isMobile}
    >
      <Chart
        type="bar"
        data={{
          labels: combinedWeeks.map((w) => w.index),
          datasets,
        }}
        options={options}
        width="100%"
        height="100px"
      />
    </Panel>
  );
};
