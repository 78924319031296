export const migrate = async () => {
  //const db = getFirestore(firebaseApp);
  /*
    const querySnapshot = await getDocs(
      collection(db, "workspaces", "TbIanbOzzVXSRCRfO8IESoOwvo73", "sessions"),
    );
    return await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        await updateDoc(doc.ref, { "data.description": doc.data().sessionNotes });
      }),
    );*/
};
