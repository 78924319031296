import { Header, HeaderProps } from "../Header";
import { ReactNode } from "react";
import styled from "styled-components";

export type RouteLayoutProps = {
  header?: HeaderProps;
  children?: ReactNode;
  padding?: boolean;
};

const Content = styled.div<{ $padding?: boolean }>`
  padding: ${({ $padding }) => ($padding ? "1rem" : "0")};
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const RouteLayout = ({
  header,
  children,
  padding = true,
}: RouteLayoutProps) => {
  return (
    <>
      {header && <Header {...header} />}
      <Content $padding={!!padding}>{children}</Content>
    </>
  );
};
