import { Button } from "primereact/button";
import { RouteLayout } from "../../../layouts/RouteLayout";
import { useNavigate } from "react-router-dom";
import { DataView } from "primereact/dataview";
import { LoadingList } from "../../../components";
import { SeasonCard } from "../components/SeasonCard";
import { CreateSeasonModal } from "../containers/CreateSeasonModal";
import { useEffect, useState } from "react";
import { IntroLayout } from "../../../layouts/IntroLayout";
import { Column } from "../../../base";
import { Season, SeasonStore } from "../data/seasons";

export const SeasonListRoute = () => {
  const navigate = useNavigate();

  const [createOpen, setCreateOpen] = useState(false);

  const [seasons, setSeasons] = useState<Season[] | undefined>();

  useEffect(() => {
    SeasonStore.list().then(setSeasons);
  }, [navigate]);

  return (
    <RouteLayout
      header={{
        title: "Seasons",
        actions: (
          <Button
            size="small"
            text
            icon="pi pi-plus"
            onClick={() => setCreateOpen(true)}
            severity="secondary"
          />
        ),
      }}
    >
      {seasons && seasons.length === 0 && (
        <IntroLayout
          title="Start planning your seasons"
          description="Build a big picture clarity and focus for your training in four steps."
          action={
            <Column>
              <Button onClick={() => setCreateOpen(true)}>
                Set up a season
              </Button>
              <a href="/">Read How to plan a season</a>
            </Column>
          }
        />
      )}
      {seasons && seasons.length > 0 && (
        <DataView
          value={seasons}
          itemTemplate={(season) => (
            <SeasonCard
              season={season}
              onClick={() => navigate(`/seasons/${season.id}`)}
            />
          )}
        />
      )}
      {!seasons && <LoadingList rows={6} rowHeight="60px" />}
      <CreateSeasonModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        onCreated={(season) => navigate(`/seasons/${season.id}`)}
      />
    </RouteLayout>
  );
};
