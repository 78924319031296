import { RouteLayout } from "../../layouts/RouteLayout";
import { Column } from "../../base";
import { api } from "../../RpcApi";
import { useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";
import { InputTextarea } from "primereact/inputtextarea";
import styled from "styled-components";

const AskAIInput = styled(InputTextarea)`
  font-size: 1.5rem;
  padding: 1rem;

  &::placeholder {
    color: var(--teal-100);
  }
`;

export const AskAiRoute = () => {
  const ask = api.askai.question.useMutation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [response, setResponse] = useState<string | undefined>();

  return (
    <RouteLayout
      header={{
        title: "Ask AI",
      }}
    >
      <Column>
        <AskAIInput
          placeholder="What would you like to know?"
          disabled={loading}
          rows={1}
          autoResize
          onKeyDown={(e) => {
            setError(undefined);
            setResponse(undefined);
            if (e.key === "Enter") {
              e.preventDefault();
              setLoading(true);
              ask
                .mutateAsync({ question: e.currentTarget.value })
                .then((res) => setResponse(res.answerSentence))
                .catch((err) => setError(err.message))
                .finally(() => setLoading(false));
            }
          }}
        />

        <Column style={{ padding: "2rem 1rem", alignItems: "center" }}>
          {loading && <ProgressSpinner />}
          {error && <Message severity="error" text={error} />}
          {response && <h2>{response}</h2>}
        </Column>
      </Column>
    </RouteLayout>
  );
};
