import styled from "styled-components";

type RowProps = {
  gap?: "small" | "medium" | "large";
  justifyContent?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly";
};

const gaps = {
  small: "6px",
  medium: "10px",
  large: "16px",
};

export const Row = styled.div<RowProps>`
  display: flex;
  gap: ${(props) => gaps[props.gap || "medium"]};
  justify-content: ${(props) => props.justifyContent};
  align-items: center;
`;

type ColumnProps = {
  gap?: "small" | "medium" | "large";
};

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => gaps[props.gap || "medium"]};
`;
