import { Discipline } from "../data/sessions";
import {
  DataField,
  getFields,
  SessionFields,
  SessionFieldSet,
} from "../data/fields";
import { FieldSet } from "../components/FieldSet";
import { useMemo } from "react";
import { Title } from "../../../base";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";

type FieldSetEditorProps = {
  key: string;
  discipline: Discipline;
  data: SessionFields;
  onChange: (data: SessionFields) => void;
};

export const FieldSetEditor = ({
  key,
  discipline,
  data,
  onChange,
}: FieldSetEditorProps) => {
  const fields = useMemo(() => getFields(discipline), [discipline]);

  return (
    <FieldSet key={key}>
      {fields.map((field) => (
        <>
          {field.type !== "longtext" && <Title>{field.title}</Title>}
          {buildInput(field, data, onChange)}
        </>
      ))}
    </FieldSet>
  );
};

const buildInput = (
  field: DataField,
  data: SessionFieldSet,
  onChange: (data: SessionFields) => void,
) => {
  const change = (newVal: any) => {
    onChange({ ...data, [field.key]: newVal });
  };

  if (field.type === "text") {
    return (
      <InputText
        type="text"
        defaultValue={data[field.key]}
        onBlur={(e) => change(e.target.value)}
      />
    );
  }
  if (field.type === "longtext") {
    return (
      <InputTextarea
        rows={8}
        placeholder={field.title}
        style={{ gridColumn: "1 / span 2" }}
        defaultValue={data[field.key]}
        onBlur={(e) => change(e.target.value)}
      />
    );
  }
  if (field.type === "number") {
    const input = (
      <InputText
        type="number"
        defaultValue={data[field.key]}
        onBlur={(e) =>
          change(e.target.value ? parseInt(e.target.value, 10) : null)
        }
      />
    );

    if (field.unit) {
      return (
        <div
          className="p-inputgroup"
          style={{ width: "150px", height: "45px" }}
        >
          {input}
          <span className="p-inputgroup-addon">
            <p>{field.unit}</p>
          </span>
        </div>
      );
    } else return input;
  }
};
