import { IntroLayout } from "../../../layouts/IntroLayout";
import { useCallback, useEffect, useState } from "react";
import { Note, NotesSource } from "../data/notes";
import { DataView } from "primereact/dataview";
import { NoteCard } from "../components/NoteCard";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { RouteLayout } from "../../../layouts/RouteLayout";
import { LoadingList } from "components";

export const NotesListRoute = () => {
  const navigate = useNavigate();

  const [notes, setNotes] = useState<Note[] | undefined>();

  useEffect(() => {
    NotesSource.list([{ field: "editedAt", direction: "desc" }]).then(setNotes);
  }, []);

  const createNote = useCallback(async () => {
    const note = await NotesSource.create({
      title: "Untitled note",
      editedAt: new Date(),
    });
    navigate(`/notes/${note.id}`);
  }, [navigate]);

  const notesIntro = {
    title: "Notes",
    subtitle: "There is always something to note down",
    description:
      "Keep your training plan ideas, race plans, training memos, training guides, and preparation checklist at the same place with your data.",
    action: <button onClick={createNote}>Create your first note</button>,
  };

  if (notes && notes.length === 0) return <IntroLayout {...notesIntro} />;

  return (
    <RouteLayout
      header={{
        title: "Notes",
        actions: (
          <Button
            size="small"
            text
            icon="pi pi-plus"
            onClick={createNote}
            severity="secondary"
          />
        ),
      }}
    >
      {notes && (
        <DataView
          value={notes}
          itemTemplate={(note) => (
            <NoteCard
              note={note}
              onClick={() => navigate(`/notes/${note.id}`)}
            />
          )}
        />
      )}
      {!notes && <LoadingList rows={6} rowHeight="60px" />}
    </RouteLayout>
  );
};
