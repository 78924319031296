import { Button } from "primereact/button";
import { Column, Title } from "../../../base";
import { useCallback, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { DailyReviewStore } from "../data/dailyreview";
import { toDateString } from "../../../utils/date-utils";

export const DailyReviewContainer = () => {
  const [open, setOpen] = useState(false);

  const [id, setId] = useState<string | undefined>();
  const [score, setScore] = useState<number | undefined>();
  const [notes, setNotes] = useState<string>("");

  useEffect(() => {
    DailyReviewStore.observe((reviews) => {
      const today = reviews.find((r) => r.date === toDateString(new Date()));
      setId(today?.id);
      setScore(today?.score);
      setNotes(today?.notes ?? "");
    });
  }, []);

  const onSave = useCallback(async () => {
    if (id) {
      await DailyReviewStore.update(id, {
        score: score!,
        notes,
      });
    } else {
      await DailyReviewStore.create({
        date: toDateString(new Date()),
        score: score!,
        notes,
      });
    }
    setOpen(false);
  }, [id, notes, score]);

  return (
    <Column style={{ alignItems: "center" }}>
      <p>How was the day overall?</p>
      <DailyScore
        onSelect={(val) => {
          setScore(val);
          setOpen(true);
        }}
        value={score}
      />
      <Dialog
        header="Daily review"
        visible={open}
        onHide={() => setOpen(false)}
        footer={<Button onClick={onSave}>Save</Button>}
      >
        <Column gap="large">
          <div style={{ alignSelf: "center" }}>
            <DailyScore onSelect={setScore} value={score} />
          </div>
          <Title>Notes</Title>
          <InputTextarea
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
            rows={5}
            cols={30}
            autoFocus
            autoResize
          />
        </Column>
      </Dialog>
    </Column>
  );
};

const DailyScore = ({
  value,
  onSelect,
}: {
  value?: number;
  onSelect: (value: number) => void;
}) => {
  return (
    <span className="p-buttonset">
      <Button
        text={value !== -2}
        icon="pi pi-ban"
        onClick={() => onSelect(-2)}
      />
      <Button
        text={value !== -1}
        icon="pi pi-thumbs-down"
        onClick={() => onSelect(-1)}
      />
      <Button
        text={value !== 0}
        icon="pi pi-circle"
        onClick={() => onSelect(0)}
      />
      <Button
        text={value !== 1}
        icon="pi pi-thumbs-up"
        onClick={() => onSelect(1)}
      />
      <Button
        text={value !== 2}
        icon="pi pi-star"
        onClick={() => onSelect(2)}
      />
    </span>
  );
};
