import { Button } from "primereact/button";
import { RouteLayout } from "../../../layouts/RouteLayout";
import { useNavigate, useParams } from "react-router-dom";
import { SplitLayout } from "../../../layouts";
import { SessionEditor } from "../containers/SessionEditor";
import { TrainingSession, TrainingSessionSource } from "../data/sessions";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SessionList } from "../components/SessionList";
import { ActionMenu } from "../../../components/ActionMenu";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import moment from "moment";
import { WeekSummary } from "../containers/WeekSummary";
import { findActiveSeason, Season } from "../../seasons/data/seasons";
import { SeasonOverview } from "../../seasons/containers/SeasonOverview";
import { Column } from "../../../base";
import { ProgressSpinner } from "primereact/progressspinner";
export const ScheduleRoute = () => {
  const { section, id } = useParams();
  const navigate = useNavigate();

  const [season, setSeason] = useState<Season | null | undefined>(undefined);

  const [sessions, setSessions] = useState<TrainingSession[] | undefined>();

  useEffect(() => {
    findActiveSeason().then((season) => {
      setSeason(season);
    });
  }, []);

  useEffect(() => {
    return TrainingSessionSource.observe(
      setSessions,
      [],
      [
        { field: "date", direction: "asc" },
        { field: "startTime", direction: "asc" },
      ],
    );
  }, [id]);

  const filteredSessions = useMemo(() => {
    if (!season) {
      return (sessions ?? []).filter((s) =>
        moment(s.date).isSameOrAfter(moment().startOf("isoWeek")),
      );
    }
    return (sessions ?? []).filter((s) =>
      moment(s.date).isBetween(season.start, season.end),
    );
  }, [sessions, season]);

  const onDeleteSession = useCallback(() => {
    confirmDialog({
      message:
        "Do you want to delete this session? This action cannot be undone.",
      header: "Delete Session",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        TrainingSessionSource.delete(id!).then(() => navigate("/schedule"));
      },
    });
  }, [id, navigate]);

  const side = useMemo(() => {
    if (section === "week") {
      return {
        title: "Week overview",
        onClose: () => navigate("/schedule"),
        content: <WeekSummary week={id!} />,
      };
    }
    if (id) {
      return {
        title: id === "new" ? "New session" : "Session",
        onClose: () => navigate("/schedule"),
        actions: (
          <ActionMenu
            actions={[
              {
                label: "Delete session",
                icon: "pi pi-trash",
                command: onDeleteSession,
              },
            ]}
          />
        ),
        content: (
          <SessionEditor id={id} onComplete={() => navigate("/schedule")} />
        ),
      };
    }
  }, [section, id, navigate, onDeleteSession]);

  const isLoading = season === undefined || sessions === undefined;

  return (
    <RouteLayout
      padding={false}
      header={{
        title: "Schedule",
        actions: (
          <Button
            size="small"
            text
            icon="pi pi-plus"
            onClick={() => navigate("/schedule/session/new")}
            severity="secondary"
            disabled={!!id}
          />
        ),
      }}
    >
      {isLoading && <ProgressSpinner />}
      {!isLoading && (
        <SplitLayout
          main={
            <Column style={{ padding: "1rem 1rem 2rem 1rem" }} gap="large">
              {season && <SeasonOverview seasonId={season.id} />}
              <SessionList sessions={filteredSessions} active={id} />
            </Column>
          }
          side={side}
        />
      )}
      <ConfirmDialog />
    </RouteLayout>
  );
};
