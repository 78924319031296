import { DesktopSplitLayout } from "./DesktopSplitLayout";
import { ReactNode } from "react";
import { DeviceAwareLayout } from "../DeviceAwareLayout";
import { MobileSplitLayout } from "./MobileSplitLayout";

export type SplitLayoutProps = {
  main: ReactNode;
  side?: {
    title: string;
    onClose: () => void;
    content: ReactNode;
    actions?: ReactNode;
  };
};
export const SplitLayout = (props: SplitLayoutProps) => (
  <DeviceAwareLayout
    style={{ display: "flex", flexDirection: "column", flex: 1 }}
    web={<DesktopSplitLayout {...props} />}
    mobile={<MobileSplitLayout {...props} />}
  />
);
