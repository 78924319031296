import styled from "styled-components";

export const FieldSet = styled.div<{ $ratio?: number; $wide?: boolean }>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.$wide ? undefined : `1fr ${props.$ratio ?? 2}fr`};

  grid-template-rows: ${(props) => (props.$wide ? "10px 1fr" : undefined)};
  align-items: center;
  gap: 1rem;
`;
