import { RouteLayout } from "../../layouts/RouteLayout";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import {
  TrainingSession,
  TrainingSessionSource,
} from "../schedule/data/sessions";
import { toDateString } from "../../utils/date-utils";
import { SessionCard } from "../schedule/components/SessionCard";
import { Column, Row, Title } from "../../base";
import { Card } from "primereact/card";
import { SplitLayout } from "../../layouts";
import { useNavigate, useParams } from "react-router-dom";
import { SessionEditor } from "../schedule/containers/SessionEditor";
import { Button } from "primereact/button";
import {
  checkAllCompleted,
  DailyCheck,
  DailyCheckStore,
  initTodayChecks,
} from "./data/dailychecks";
import { DailyCheckItem } from "./components/DailyCheckItem";
import styled from "styled-components";
import { DailyCheckSettings } from "./containers/DailyCheckSettings";
import { DailyReviewContainer } from "./containers/DailyReviewContainer";

const SlimCard = styled(Card)`
  .p-card-content {
    padding: 6px 0;
  }
`;

export const TodayRoute = () => {
  const navigate = useNavigate();

  const { sessionId } = useParams<{ sessionId?: string }>();

  const [sessions, setSessions] = useState<TrainingSession[] | undefined>();
  const [checks, setChecks] = useState<DailyCheck[] | undefined>();

  useEffect(() => {
    return TrainingSessionSource.observe(
      setSessions,
      [{ field: "date", comparator: "==", value: toDateString(new Date()) }],
      [{ field: "startTime", direction: "asc" }],
    );
  }, []);

  useEffect(() => {
    initTodayChecks();
    return DailyCheckStore.observe(
      async (checks) => {
        setChecks(checks);
        await checkAllCompleted();
      },
      [{ field: "date", comparator: "==", value: toDateString(new Date()) }],
    );
  }, []);

  const side = useMemo(() => {
    if (sessionId) {
      return (
        <SessionEditor id={sessionId} onComplete={() => navigate("/today")} />
      );
    }
  }, [sessionId, navigate]);

  const onNewCheck = useCallback(() => {
    DailyCheckStore.create({
      date: toDateString(new Date()),
      title: "New check",
      completed: false,
    });
  }, []);

  const onCheckChanged = useCallback((check: DailyCheck) => {
    const { id, ...updates } = check;
    DailyCheckStore.update(id, updates);
  }, []);

  return (
    <RouteLayout
      header={{
        title: "Today",
      }}
    >
      <SplitLayout
        main={
          <Column style={{ maxWidth: "700px", margin: "auto" }}>
            <h3>Today's sessions</h3>

            {sessions && (
              <CompleteStateContainer
                title="training"
                items={sessions}
                renderItem={(s) => (
                  <SlimCard onClick={() => navigate(`/today/session/${s.id}`)}>
                    <SessionCard session={s} list={false} showDate={false} />
                  </SlimCard>
                )}
                isCompleted={(s) => s.status === "completed"}
              />
            )}

            <Row style={{ marginTop: "2rem" }}>
              <h3>Daily checks</h3>
              <Button text icon="pi pi-plus" onClick={onNewCheck} />
              <div style={{ marginLeft: "auto" }}>
                <DailyCheckSettings />
              </div>
            </Row>

            {checks && (
              <CompleteStateContainer
                title="checks"
                items={checks}
                renderItem={(c) => (
                  <DailyCheckItem
                    key={c.id}
                    check={c}
                    onChanged={onCheckChanged}
                    onRemove={() => DailyCheckStore.delete(c.id)}
                  />
                )}
                isCompleted={(c) => c.completed}
              />
            )}

            <h3 style={{ marginTop: "2rem", marginBottom: 0 }}>Daily review</h3>
            <DailyReviewContainer />
          </Column>
        }
        side={
          side && {
            title: "Session",
            onClose: () => navigate("/today"),
            content: side,
          }
        }
      />
    </RouteLayout>
  );
};

type CompleteStateContainerProps = {
  title: string;
  items: any[];
  renderItem: (item: any) => ReactNode;
  isCompleted: (item: any) => boolean;
};

export const CompleteStateContainer = ({
  title,
  items,
  renderItem,
  isCompleted,
}: CompleteStateContainerProps) => {
  const [showCompleted, setShowCompleted] = useState(false);

  const allCompleted = useMemo(() => {
    return items.every(isCompleted);
  }, [items, isCompleted]);

  return (
    <Column>
      {allCompleted && !showCompleted && (
        <Completed
          title={`All ${title} completed`}
          action="Show"
          onAction={() => setShowCompleted(true)}
        />
      )}
      {(!allCompleted || showCompleted) && (
        <Column>{items.map(renderItem)}</Column>
      )}
      {allCompleted && showCompleted && (
        <div style={{ alignSelf: "center" }}>
          <Button
            text
            severity="secondary"
            onClick={() => setShowCompleted(false)}
          >
            Hide
          </Button>
        </div>
      )}
    </Column>
  );
};

export const Completed = ({
  title,
  action,
  onAction,
}: {
  title: string;
  action?: string;
  onAction?: () => void;
}) => (
  <Column
    style={{ justifyContent: "center", alignItems: "center" }}
    gap="large"
  >
    <i
      className="pi pi-check-circle"
      style={{ fontSize: "3rem", color: "var(--green-500)" }}
    />
    <Title>{title}</Title>
    {action && (
      <div>
        <Button text onClick={onAction} severity="secondary">
          {action}
        </Button>
      </div>
    )}
  </Column>
);
