import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { waitForUserLoading, ZoneUser } from "./business/auth";
import { AppLayout, AppMenu } from "./layouts";
import styled from "styled-components";
import { ProgressSpinner } from "primereact/progressspinner";

const WrapperContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppWrapper = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState<ZoneUser | null>(null);

  useEffect(() => {
    waitForUserLoading()
      .then((user) => {
        if (!user) navigate("/login");
        else setUser(user);
      })
      .catch(() => {
        navigate("/login");
      });
  }, [navigate, user]);

  if (user === null)
    return (
      <WrapperContainer>
        <ProgressSpinner strokeWidth="3" />
      </WrapperContainer>
    );
  return <AppLayout menu={<AppMenu />} content={<Outlet />} />;
};
