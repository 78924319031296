import { RouteLayout } from "layouts/RouteLayout";
import {
  Season,
  SeasonStore,
  SeasonWeek,
  SeasonWeekStore,
} from "../data/seasons";
import { useParams } from "react-router-dom";

import {
  Column as TableColumn,
  ColumnEditorOptions,
  ColumnEvent,
} from "primereact/column";
import { DataTable } from "primereact/datatable";
import { SeasonOverview } from "../containers/SeasonOverview";
import { Column, Row, Title } from "base";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Filters } from "../../../business/data/entity-source";
import { ProgressSpinner } from "primereact/progressspinner";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

const CellTextInput = styled(InputText)`
  &:focus {
    box-shadow: none;
  }
`;

const CellNumberInput = styled(InputNumber)`
  input:focus {
    box-shadow: none;
  }
`;

export const SeasonRoute = () => {
  const { id } = useParams<{ id: string }>();

  const [season, setSeason] = useState<Season | undefined>();
  const [weeks, setWeeks] = useState<SeasonWeek[] | undefined>();

  const [groupBy, setGroupBy] = useState("month");

  useEffect(() => {
    SeasonStore.find(id!).then(setSeason);
  }, [id]);

  useEffect(() => {
    SeasonWeekStore.listBy(
      [Filters.eq("seasonId", id!)],
      [{ field: "start", direction: "asc" }],
    ).then((weeks) => {
      setWeeks(weeks);
    });
  }, [id]);

  const columns = [
    { field: "index", header: "#", width: "45px" },
    {
      field: "week",
      header: "Week",
      width: "120px",
      template: (rowData: SeasonWeek) => {
        const isSameMonth = moment(rowData.start).isSame(rowData.end, "month");

        return `${moment(rowData.start).format("MMM D")} - ${moment(
          rowData.end,
        ).format(isSameMonth ? "D" : "MMM D")}`;
      },
    },
    { field: "block", header: "Block", width: "100px", type: "text" },
    { field: "title", header: "Title", width: "200px", type: "text" },
    { field: "totalCount", header: "Time (h)", width: "70px", type: "number" },
    { field: "swimCount", header: "Swim", width: "70px", type: "number" },
    { field: "bikeCount", header: "Bike", width: "70px", type: "number" },
    { field: "runCount", header: "Run", width: "70px", type: "number" },
    {
      field: "strengthCount",
      header: "Strength",
      width: "70px",
      type: "number",
    },
    { field: "otherCount", header: "Other", width: "70px", type: "number" },
  ];

  const cellEditor = (options: ColumnEditorOptions, type: string) => {
    if (type === "text") {
      return (
        <CellTextInput
          autoFocus
          type={type ?? "text"}
          value={options.value}
          style={{
            width: "100%",
            border: "none",
            borderRadius: 0,
            outline: "none",
          }}
          onChange={(e) => {
            options.editorCallback &&
              options.editorCallback(e.currentTarget.value);
          }}
        />
      );
    }
    if (type === "number") {
      return (
        <CellNumberInput
          autoFocus
          value={options.value}
          inputStyle={{
            width: "100%",
            border: "none",
            borderRadius: 0,
            outline: "none",
          }}
          onChange={(e) => {
            options.editorCallback && options.editorCallback(e.value);
          }}
        />
      );
    }
  };

  const onCellEditComplete = async (e: ColumnEvent) => {
    await SeasonWeekStore.update(e.rowData.id, {
      [e.field]: e.newValue ?? null,
    }).then((updated) => console.log(updated));
  };

  const footerOf = (field: string) => {
    if (field === "index") return weeks?.length;
    if (
      [
        "totalCount",
        "swimCount",
        "bikeCount",
        "runCount",
        "strengthCount",
        "otherCount",
      ].includes(field)
    ) {
      return weeks?.reduce((acc, week) => {
        return acc + ((week as any)[field] ?? 0);
      }, 0);
    }

    return "";
  };

  return (
    <RouteLayout
      header={{
        back: { path: "/seasons" },
        title: season?.title,
      }}
    >
      {!weeks && <ProgressSpinner />}
      <Column style={{ overflowY: "hidden" }}>
        <SeasonOverview seasonId={id!} />
        <Row>
          <Button
            outlined={groupBy !== "month"}
            onClick={() => setGroupBy("month")}
          >
            By month
          </Button>
          <Button
            outlined={groupBy !== "block"}
            onClick={() => setGroupBy("block")}
          >
            By block
          </Button>
        </Row>
        <DataTable
          value={weeks ?? []}
          editMode="cells"
          showGridlines
          tableStyle={{ tableLayout: "fixed", borderRadius: "8px" }}
          rowGroupMode="subheader"
          groupRowsBy={groupBy}
          rowGroupHeaderTemplate={(data) => (
            <Title style={{ padding: 0, margin: 0 }}>{data[groupBy]}</Title>
          )}
          scrollable
          scrollHeight="75vh"
        >
          {columns.map(({ field, header, width, type, template }) => {
            return (
              <TableColumn
                key={field}
                field={field}
                header={<Title style={{ padding: "16px" }}>{header}</Title>}
                footer={
                  <Title style={{ padding: "16px" }}>{footerOf(field)}</Title>
                }
                body={(rowData) => (
                  <Title style={{ padding: "16px" }}>
                    {template ? template(rowData) : rowData[field]}
                  </Title>
                )}
                style={{ padding: 0, width: width ?? "20%" }}
                editor={
                  !!type ? (options) => cellEditor(options, type) : undefined
                }
                onCellEditComplete={onCellEditComplete}
              />
            );
          })}
        </DataTable>
      </Column>
    </RouteLayout>
  );
};
