import { useCallback, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Column, Title } from "base";
import { FieldSet } from "../../schedule/components/FieldSet";
import { saveSeasonWeeks, Season, SeasonStore } from "../data/seasons";
import { useToast } from "../../../hooks/use-toast";
import { toDateString } from "../../../utils/date-utils";

type CreateSeasonModalProps = {
  open: boolean;
  onClose: () => void;
  onCreated: (season: Season) => void;
};

export const CreateSeasonModal = ({
  open,
  onClose,
  onCreated,
}: CreateSeasonModalProps) => {
  const toast = useToast();

  const [title, setTitle] = useState("");
  const [start, setStart] = useState<Date | null | undefined>(null);
  const [end, setEnd] = useState<Date | null | undefined>(null);

  const [loading, setLoading] = useState(false);

  const onCreate = useCallback(() => {
    setLoading(true);
    SeasonStore.create({
      title,
      start: toDateString(start!),
      end: toDateString(end!),
    })
      .then(async (season) => {
        await saveSeasonWeeks(season);
        onCreated(season);
      })
      .catch(() => {
        toast.error("Create failed");
      })
      .finally(() => setLoading(false));
  }, [title, start, end, toast, onCreated]);

  return (
    <Dialog
      header="Create season"
      onHide={onClose}
      visible={open}
      footer={
        <Button onClick={onCreate} loading={loading}>
          Create season
        </Button>
      }
    >
      <Column>
        <FieldSet>
          <Title>Title</Title>
          <InputText
            placeholder="Season title"
            value={title}
            onInput={(e) => setTitle(e.currentTarget.value)}
          />
          <Title>Start</Title>
          <Calendar
            showIcon
            value={start}
            onChange={(e) => setStart(e.value)}
          />
          <Title>End</Title>
          <Calendar showIcon value={end} onChange={(e) => setEnd(e.value)} />
        </FieldSet>
      </Column>
    </Dialog>
  );
};
