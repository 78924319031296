import { PopupAppMenu } from "./PopupAppMenu";
import { SideAppMenu } from "./SideAppMenu";

export type AppMenuProps = {
  items?: { path: string; title: string }[];
  popup?: boolean;
};

export const AppMenu = ({ popup, items }: AppMenuProps) => {
  const defaultItems = [
    { path: "/today", title: "Today" },
    { path: "/schedule", title: "Schedule" },
    { path: "/progressions", title: "Progressions" },
    { path: "/seasons", title: "Season" },
    { path: "/askai", title: "Ask AI" },
    { path: "/notes", title: "Notes" },
  ];

  if (popup) return <PopupAppMenu items={items ?? defaultItems} />;
  else return <SideAppMenu items={items ?? defaultItems} />;
};
