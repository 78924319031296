import { CSSProperties, ReactNode } from "react";
import { MobileView, BrowserView } from "react-device-detect";

export type DeviceAware = {
  web: ReactNode;
  mobile?: ReactNode;
  style?: CSSProperties;
};

export const DeviceAwareLayout = ({ web, mobile, style }: DeviceAware) => {
  return (
    <>
      <MobileView style={style}>{mobile ?? web}</MobileView>
      <BrowserView style={style}>{web}</BrowserView>
    </>
  );
};
