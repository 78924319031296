import { logout } from "../../business/auth";
import { Button, ButtonProps } from "primereact/button";

export const LogoutButton = (props: ButtonProps) => {
  return (
    <Button
      severity="secondary"
      text
      onClick={() => logout().then(() => (window.location.href = "/"))}
      {...props}
    >
      Logout
    </Button>
  );
};
