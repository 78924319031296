import { Dialog } from "primereact/dialog";
import {
  SessionStatusSchema,
  TrainingSession,
  TrainingSessionSource,
} from "../data/sessions";
import { FloatButton } from "../components/FloatButton";
import { useCallback, useState } from "react";
import { Divider } from "primereact/divider";
import { Button } from "primereact/button";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Panel } from "primereact/panel";
import { FieldSetEditor } from "./FieldSetEditor";
import { SessionFieldSet } from "../data/fields";

type CompleteSessionDialogProps = {
  session: TrainingSession;
  onComplete: () => void;
};

const CompleteButton = styled(Button)`
  width: 100%;
  margin-top: 2rem;
`;

export const CompleteSessionDialog = ({
  session,
  onComplete,
}: CompleteSessionDialogProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<SessionFieldSet>({
    ...session.planned,
    description: "",
  });

  const [planCollapsed, setPlanCollapsed] = useState(true);

  const complete = useCallback(async () => {
    setLoading(true);
    await TrainingSessionSource.update(session.id, {
      data,
      status: SessionStatusSchema.Values.completed,
    })
      .then(() => {
        setLoading(false);
        setOpen(false);
        onComplete && onComplete();
      })
      .finally(() => setLoading(false));
  }, [data, session, onComplete]);

  return (
    <>
      <Dialog
        onHide={() => setOpen(false)}
        visible={open}
        header="Complete session"
        style={{ width: "500px" }}
        maximized={isMobile}
      >
        <h4>{session.title}</h4>
        <Panel
          header="Plan"
          toggleable
          collapsed={planCollapsed}
          onToggle={() => setPlanCollapsed(!planCollapsed)}
        >
          <p style={{ whiteSpace: "pre-line", margin: 0 }}>
            {session.planned.description}
          </p>
        </Panel>

        <Divider />

        <FieldSetEditor
          key={`complete-${session.id}`}
          discipline={session.discipline}
          data={data}
          onChange={(data) => setData(data)}
        />

        <CompleteButton label="Complete" onClick={complete} loading={loading} />
      </Dialog>
      <FloatButton label="Complete" onClick={() => setOpen(true)} />
    </>
  );
};
