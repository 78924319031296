import { useNavigate, useParams } from "react-router-dom";
import { Note, NotesSource } from "../data/notes";
import { useCallback, useEffect, useState } from "react";

import {
  convertFromRaw,
  convertToRaw,
  Editor,
  EditorState,
  RawDraftContentState,
} from "draft-js";
import "draft-js/dist/Draft.css";

import ContentEditable from "react-contenteditable";
import { ActionMenu } from "../../../components/ActionMenu";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import styled from "styled-components";
import { RouteLayout } from "../../../layouts/RouteLayout";

const NoteContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const NoteEditRoute = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [note, setNote] = useState<Note | undefined>();
  const [contentState, setContentState] = useState(() =>
    EditorState.createEmpty(),
  );

  const onDeleteNote = () => {
    confirmDialog({
      message: "Do you want to delete this note? This action cannot be undone.",
      header: "Delete Note",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        NotesSource.delete(note!.id).then(() => navigate("/notes"));
      },
    });
  };

  useEffect(() => {
    if (!id) return;
    NotesSource.find(id).then((note) => {
      setNote(note);

      if (note.content) {
        setContentState(
          EditorState.createWithContent(
            convertFromRaw(note.content as RawDraftContentState),
          ),
        );
      }
    });
  }, [id]);

  const saveChanges = useCallback(async () => {
    const content = convertToRaw(contentState.getCurrentContent());

    await NotesSource.update(note!.id, {
      ...note,
      content,
      editedAt: new Date(),
    });
  }, [note, contentState]);

  if (!note) return null;

  return (
    <RouteLayout
      header={{
        variant: "transparent",
        back: { path: "/notes", title: "Notes" },
        actions: (
          <ActionMenu
            actions={[
              {
                label: "Delete note",
                icon: "pi pi-trash",
                command: onDeleteNote,
              },
            ]}
          />
        ),
      }}
    >
      <NoteContainer>
        <ContentEditable
          html={note.title}
          disabled={false}
          onChange={(e) =>
            setNote({
              ...note,
              title: e.currentTarget.textContent ?? "Untitled note",
            })
          }
          onBlur={saveChanges}
          tagName="h2"
        />
        <div style={{ height: "80vh", marginTop: "32px" }}>
          <Editor
            editorState={contentState}
            onChange={setContentState}
            onBlur={saveChanges}
          />
        </div>
      </NoteContainer>
      <ConfirmDialog />
    </RouteLayout>
  );
};
