import styled from "styled-components";
import { AppLayoutProps } from "./index";

export const DesktopAppLayout = ({ menu, content }: AppLayoutProps) => {
  return (
    <RootLayout>
      <MenuSection>{menu}</MenuSection>
      <ContentSection>{content}</ContentSection>
    </RootLayout>
  );
};

const RootLayout = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100%;
  background: #f8f8f9;
`;

const MenuSection = styled.section`
  position: fixed;
  width: 200px;
  height: 100%;
  border-right: 1px solid #eaeaea;
`;

const ContentSection = styled.section`
  flex: 1;
  margin-left: 200px;
  display: flex;
  flex-direction: column;
`;
