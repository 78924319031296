export type IdentifiableEntity = { id: string };

export type Ordering = {
  field: string;
  direction: "asc" | "desc";
};

export type Filter = {
  field: string;
  comparator: string;
  value: string;
};

export const Filters = {
  eq: (field: string, value: string): Filter => ({
    field,
    comparator: "==",
    value,
  }),
};

export interface EntitySource<T extends IdentifiableEntity> {
  create(workspaceId: string, entity: Omit<T, "id">): Promise<T>;
  update(
    workspaceId: string,
    id: string,
    entity: Partial<Omit<T, "id">>,
  ): Promise<T>;
  delete(workspaceId: string, id: string): Promise<void>;

  find(workspaceId: string, id: string): Promise<T>;
  list(workspaceId: string, ordering?: Ordering[]): Promise<T[]>;

  listBy(
    workspaceId: string,
    filters: Filter[],
    ordering?: Ordering[],
  ): Promise<T[]>;

  observe(
    workspaceId: string,
    callback: (entities: T[]) => void,
    filters?: Filter[],
    ordering?: Ordering[],
  ): VoidFunction;

  observeOne(
    workspaceId: string,
    id: string,
    callback: (entity: T) => void,
  ): VoidFunction;
}
