import { SplitLayoutProps } from "./index";
import styled from "styled-components";
import { Sidebar } from "primereact/sidebar";

const Root = styled.div``;

export const MobileSplitLayout = ({ main, side }: SplitLayoutProps) => {
  return (
    <Root>
      {main}
      {side && (
        <Sidebar
          fullScreen
          visible={!!side}
          onHide={side!.onClose}
          header={<h3>{side.title}</h3>}
          icons={side!.actions}
        >
          {side.content}
        </Sidebar>
      )}
    </Root>
  );
};
