import { SplitLayoutProps } from "./index";
import styled from "styled-components";
import { Row } from "base";
import { Button } from "primereact/button";

const Root = styled.div`
  display: flex;
  flex: 1;
`;

const MainContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  background: #f8f8f9;
`;

const SideContainer = styled.div`
  width: 500px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  background: #fff;
  border-left: 1px solid #e0e0e0;
`;

export const DesktopSplitLayout = ({ main, side }: SplitLayoutProps) => {
  return (
    <Root>
      <MainContainer>{main}</MainContainer>
      {side && (
        <SideContainer>
          <Row
            style={{ padding: "12px 1rem", justifyContent: "space-between" }}
          >
            <h3>{side?.title}</h3>
            <Row>
              {side?.actions}
              <Button
                text
                icon="pi pi-times"
                onClick={side?.onClose}
                severity="secondary"
              />
            </Row>
          </Row>
          {side?.content}
        </SideContainer>
      )}
    </Root>
  );
};
