import { TrainingSession } from "../data/sessions";
import { Row, SubTitle, Title } from "base";
import styled from "styled-components";
import { fromDateString } from "../../../utils/date-utils";
import moment from "moment";
import { StatusTag } from "./StatusTag";
import { LetterA, LetterB } from "assets";
import { DisciplineIcon } from "../../../assets/DisciplineIcon";

type SessionCardProps = {
  session: TrainingSession;
  active?: boolean;
  onClick?: () => void;
  list?: boolean;
  showDate?: boolean;
};

const Root = styled.div<{ $active?: boolean; $list: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: ${(props) => (props.$list ? "1rem" : "0")};
  cursor: pointer;
  background-color: ${({ $active }) => ($active ? "#f8f8f9" : "transparent")};

  &:hover {
    background-color: ${(props) => (props.$list ? "#f8f8f9" : undefined)};
  }
`;

export const SessionCard = ({
  session,
  active,
  onClick,
  list = true,
  showDate = true,
}: SessionCardProps) => {
  return (
    <Root $active={!!active} onClick={onClick} $list={list}>
      <DisciplineIcon discipline={session.discipline} />
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Title>{session.title}</Title>
        <SubTitle>
          {showDate &&
            moment(fromDateString(session.date)).format("dddd MMM DD")}{" "}
          {session.startTime}
        </SubTitle>
      </div>
      <Row>
        {session.priority === 2 && <LetterB />}
        {session.priority === 1 && <LetterA />}
        <StatusTag status={session.status} style={{ width: "80px" }} />
      </Row>
    </Root>
  );
};
