import { z } from "zod";
import { Discipline } from "./sessions";
import { cloneDeep } from "lodash";

export const SessionFieldsSchema = z.object({
  duration: z.number().nullish(),
  distance: z.number().nullish(),
  description: z.string().nullish(),
  hr_avg: z.number().nullish(),
  power_avg: z.number().nullish(),
});
export type SessionFields = z.infer<typeof SessionFieldsSchema>;
export type SessionFieldSet = { [index: string]: any };

export const DataFieldSchema = z.object({
  key: z.string(),
  title: z.string(),
  unit: z.string().optional(),
  type: z.enum(["number", "string", "text", "longtext"]),
});
export type DataField = z.infer<typeof DataFieldSchema>;

export const DataFields: DataField[] = [
  {
    key: "duration",
    title: "Duration",
    unit: "min",
    type: "number",
  },
  {
    key: "distance",
    title: "Distance",
    unit: "km",
    type: "number",
  },
  {
    key: "hr_avg",
    title: "HR avg",
    unit: "bpm",
    type: "number",
  },
  {
    key: "power_avg",
    title: "Power avg",
    unit: "w",
    type: "number",
  },
  {
    key: "description",
    title: "Description",
    type: "longtext",
  },
];

const pickFields = (...keys: string[]) => {
  return cloneDeep(DataFields).filter((f) =>
    keys.includes(f.key),
  ) as DataField[];
};

export const getFields = (discipline: Discipline): DataField[] => {
  if (discipline === "swim") {
    const fields = pickFields("duration", "distance", "description");
    fields.find((f) => f.key === "distance")!.unit = "m";
    return fields;
  }
  if (discipline === "bike") {
    return pickFields(
      "duration",
      "distance",
      "hr_avg",
      "power_avg",
      "description",
    );
  }
  if (discipline === "run") {
    return pickFields("duration", "distance", "hr_avg", "description");
  }
  if (discipline === "strength") {
    return pickFields("duration", "description");
  }
  if (discipline === "other") {
    return pickFields("duration", "description");
  }
  return [];
};
