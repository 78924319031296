import { DateString } from "../../../utils/date-utils";
import { SummarySection, useWeekSummary } from "../data/week";
import { FieldSet } from "../components/FieldSet";
import { Title } from "../../../base";
import { formatMinutes } from "../../../utils/time-utils";
import moment from "moment";

type WeekSummaryProps = {
  week: DateString;
};

export const WeekSummary = ({ week }: WeekSummaryProps) => {
  const summary = useWeekSummary(week);
  const previousWeek = useWeekSummary(
    moment(week).subtract(1, "week").format("YYYY-MM-DD"),
  );

  const valueTitle = (
    discipline: SummarySection,
    field: string,
    format: (val: any) => string = (val) => val,
  ) => {
    const val = summary.get(discipline, field);
    const lastWeekVal = previousWeek.get(discipline, field);

    const comparison =
      val.planned - previousWeek.get(discipline, field).completed;

    return (
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}>
        <Title>{format(val.completed)}</Title>
        <Title style={{ color: "gray" }}>({format(val.planned)})</Title>
        <Title>{format(lastWeekVal.completed)}</Title>
        <Title
          style={{
            color: comparison > 0 ? "green" : "red",
          }}
        >
          {comparison > 0 && "+"}
          {comparison !== 0 && format(comparison)}
        </Title>
      </div>
    );
  };

  const dataSet = (discipline: SummarySection, fields: string[]) => {
    const summaryFields = [
      { key: "count", title: "Sessions" },
      { key: "duration", title: "Duration", format: formatMinutes },
      { key: "distance", title: "Distance" },
    ];

    const selectedFields = summaryFields.filter((f) => fields.includes(f.key));

    return (
      <FieldSet $ratio={3} style={{ margin: "2rem 0" }}>
        {selectedFields.map((f) => (
          <>
            <Title>{f.title}</Title>
            {valueTitle(discipline, f.key, f.format)}
          </>
        ))}
      </FieldSet>
    );
  };

  return (
    <div style={{ margin: "0 1rem" }}>
      <h3>{summary.title}</h3>

      <FieldSet $ratio={3}>
        <div />
        <div
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr" }}
        >
          <Title>completed</Title>
          <Title style={{ color: "gray" }}>planned</Title>
          <Title>Last week</Title>
          <Title>Change</Title>
        </div>
      </FieldSet>

      {dataSet("total", ["count", "duration"])}

      <h4>Swim</h4>
      {dataSet("swim", ["count", "duration", "distance"])}

      <h4>Bike</h4>
      {dataSet("bike", ["count", "duration", "distance"])}

      <h4>Run</h4>
      {dataSet("run", ["count", "duration", "distance"])}

      <h4>Strength</h4>
      {dataSet("strength", ["count", "duration"])}

      <h4>Other</h4>
      {dataSet("other", ["count", "duration"])}
    </div>
  );
};
