import styled from "styled-components";
import { HeaderProps } from "./index";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Row } from "base";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 1rem;
  height: 60px;
  background: #fff;
`;

export const DesktopHeader = ({ title, actions, back }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Header>
      <Row>
        {back && (
          <Button
            text
            icon="pi pi-angle-left"
            severity="secondary"
            size="small"
            onClick={() => navigate(back.path)}
          >
            {back.title}
          </Button>
        )}
        {title && <h4>{title}</h4>}
      </Row>
      {actions}
    </Header>
  );
};
