import styled from "styled-components";
import { Button } from "primereact/button";
import { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { AppMenuProps } from "./index";
import { Link } from "react-router-dom";
import { LogoutButton } from "./LogoutButton";

const ActionButton = styled(Button)`
  &:enabled:focus {
    box-shadow: none;
  }
`;

const MainMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1rem;
  height: 100%;
`;

export const PopupAppMenu = ({ items }: AppMenuProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Sidebar
        visible={open}
        onHide={() => setOpen(false)}
        header={<h2>Zone</h2>}
      >
        <MainMenu>
          {items!.map((i, index) => (
            <Link key={index} to={i.path} onClick={() => setOpen(false)}>
              {i.title}
            </Link>
          ))}

          <LogoutButton style={{ marginTop: "auto" }} />
        </MainMenu>
      </Sidebar>
      <ActionButton
        size="small"
        severity="secondary"
        icon="pi pi-bars"
        text
        aria-label="Menu"
        onClick={(event) => setOpen(true)}
      />
    </>
  );
};
