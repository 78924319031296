import styled from "styled-components";
import { ReactNode } from "react";

type IntroLayoutProps = {
  title: string;
  subtitle?: string;
  description: string;
  action?: ReactNode;
};

const IntroLayoutRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100vh;
`;

export const IntroLayout = ({
  title,
  subtitle,
  description,
  action,
}: IntroLayoutProps) => {
  return (
    <IntroLayoutRoot>
      <h2>{title}</h2>
      {subtitle && <p>{subtitle}</p>}
      <p style={{ width: "500px" }}>{description}</p>
      {action}
    </IntroLayoutRoot>
  );
};
