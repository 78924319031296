import { getFirestore } from "firebase/firestore";

import {
  collection,
  setDoc,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  orderBy,
  query,
  where,
  onSnapshot,
  FirestoreDataConverter,
  WhereFilterOp,
  DocumentData,
  QueryDocumentSnapshot,
  Timestamp,
} from "firebase/firestore";
import {
  EntitySource,
  Filter,
  IdentifiableEntity,
  Ordering,
} from "./entity-source";
import { z, ZodObject } from "zod";
import { uuid } from "./utils";
import { firebaseApp } from "../firebase";

const db = getFirestore(firebaseApp);

const baseSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
});

const dateConverter: FirestoreDataConverter<object> = {
  toFirestore(data: object): DocumentData {
    return data;
  },

  fromFirestore(docSnap: QueryDocumentSnapshot): object {
    const data = docSnap.data();
    return Object.keys(data).reduce((handled, key) => {
      if (data[key] instanceof Timestamp)
        return { ...handled, [key]: data[key].toDate() };
      return handled;
    }, data);
  },
};

export type ErrorCallback = (error: Error) => void;

export class FirestoreEntitySource<T extends IdentifiableEntity>
  implements EntitySource<T>
{
  private schema;

  constructor(
    private entityName: string,
    private entitySchema: ZodObject<any>,
  ) {
    this.schema = entitySchema.and(baseSchema);
  }

  private getCollection = (workspaceId: string) => {
    return collection(
      db,
      "workspaces",
      workspaceId,
      this.entityName,
    ).withConverter(dateConverter);
  };

  private docRef = (workspaceId: string, docId: string) => {
    return doc(
      db,
      "workspaces",
      workspaceId,
      this.entityName,
      docId,
    ).withConverter(dateConverter);
  };

  create = async (workspaceId: string, entity: Omit<T, "id">): Promise<T> => {
    const parsed = this.schema.parse({ ...entity, id: uuid() });

    const doc = this.docRef(workspaceId, parsed.id);

    await setDoc(doc, parsed);
    return this.find(workspaceId, parsed.id);
  };

  find = async (workspaceId: string, id: string): Promise<T> => {
    const doc = this.docRef(workspaceId, id);
    const snap = await getDoc(doc);

    if (snap.exists()) return this.entitySchema.parse(snap.data()) as T;
    else throw new Error(`Entity ${this.entityName} with id ${id} not found`);
  };

  update = async (
    workspaceId: string,
    id: string,
    update: Partial<Omit<T, "id">>,
  ): Promise<T> => {
    const parsedUpdates = this.entitySchema
      .omit({ id: true })
      .partial()
      .parse(update);

    await updateDoc(this.docRef(workspaceId, id), parsedUpdates);

    return this.find(workspaceId, id);
  };

  list = async (workspaceId: string, ordering?: Ordering[]): Promise<T[]> => {
    const querySnapshot = await getDocs(
      this.buildQuery(workspaceId, [], ordering),
    );
    return querySnapshot.docs.map((doc) => {
      return this.entitySchema.parse(doc.data()) as T;
    });
  };

  listBy = async (
    workspaceId: string,
    filter: Filter[],
    ordering?: Ordering[],
  ): Promise<T[]> => {
    const querySnapshot = await getDocs(
      this.buildQuery(workspaceId, filter, ordering),
    );
    return querySnapshot.docs.map((doc) => {
      return this.entitySchema.parse(doc.data()) as T;
    });
  };

  observe = (
    workspaceId: string,
    callback: (entities: T[]) => void,
    filters?: Filter[],
    ordering?: Ordering[],
    onError?: ErrorCallback,
  ): VoidFunction => {
    return onSnapshot(
      this.buildQuery(workspaceId, filters ?? [], ordering),
      (querySnapshot) => {
        const entities = querySnapshot.docs.map((doc) => {
          return this.entitySchema.parse(doc.data()) as T;
        });
        callback(entities);
      },
      onError,
    );
  };

  observeOne = (
    workspaceId: string,
    id: string,
    callback: (entity: T) => void,
    onError?: ErrorCallback,
  ): VoidFunction => {
    return onSnapshot(
      this.docRef(workspaceId, id),
      (doc) => {
        const entity = this.entitySchema.parse(doc.data()) as T;
        callback(entity);
      },
      onError,
    );
  };

  private buildQuery = (
    workspaceId: string,
    filters: Filter[],
    ordering?: Ordering[],
  ) => {
    const wheres = filters.map((f) =>
      where(f.field, f.comparator as WhereFilterOp, f.value),
    );

    const orderings = (ordering || []).map((o) =>
      orderBy(o.field, o.direction),
    );
    return query(this.getCollection(workspaceId), ...wheres, ...orderings);
  };

  delete = async (workspaceId: string, id: string): Promise<void> => {
    await deleteDoc(this.docRef(workspaceId, id));
  };
}

export class FirestoreWorkspaceEntitySource<T extends IdentifiableEntity> {
  private store: FirestoreEntitySource<T>;
  constructor(
    entityName: string,
    entitySchema: ZodObject<any>,
    private loadWorkspace: () => string,
  ) {
    this.store = new FirestoreEntitySource(entityName, entitySchema);
  }

  create = async (entity: Omit<T, "id">): Promise<T> => {
    return this.store.create(this.loadWorkspace(), entity);
  };

  find = async (id: string): Promise<T> => {
    return this.store.find(this.loadWorkspace(), id);
  };

  update = async (id: string, update: Partial<Omit<T, "id">>): Promise<T> => {
    return this.store.update(this.loadWorkspace(), id, update);
  };

  list = async (ordering?: Ordering[]): Promise<T[]> => {
    return this.store.list(this.loadWorkspace(), ordering);
  };

  listBy = async (filter: Filter[], ordering?: Ordering[]): Promise<T[]> => {
    return this.store.listBy(this.loadWorkspace(), filter, ordering);
  };

  observe = (
    callback: (entities: T[]) => void,
    filters?: Filter[],
    ordering?: Ordering[],
    onError?: ErrorCallback,
  ): VoidFunction => {
    return this.store.observe(
      this.loadWorkspace(),
      callback,
      filters,
      ordering,
      onError,
    );
  };
  observeOne(
    id: string,
    callback: (entity: T) => void,
    onError?: ErrorCallback,
  ): VoidFunction {
    return this.store.observeOne(this.loadWorkspace(), id, callback, onError);
  }

  delete = async (id: string): Promise<void> => {
    return this.store.delete(this.loadWorkspace(), id);
  };
}
