import moment from "moment";
import { z } from "zod";

export const toDateString = (date: Date): string => {
  return moment(date).format("YYYY-MM-DD");
};

export const fromDateString = (dateString?: string): Date => {
  return moment(dateString, "YYYY-MM-DD").toDate();
};

export const fromTimeString = (timeString: string): Date => {
  return moment(timeString, "HH:mm").toDate();
};

export const toTimeString = (date: Date): string => {
  return moment(date).format("HH:mm");
};

export const DateStringSchema = z
  .string()
  .refine(
    (value) => moment(value, "YYYY-MM-DD").isValid(),
    "Not a valid date string",
  );
export type DateString = z.infer<typeof DateStringSchema>;

export const TimeSchema = z
  .string()
  .refine(
    (value) => moment(value, "HH:mm").isValid(),
    "Not a valid time string",
  );
export type Time = z.infer<typeof TimeSchema>;
