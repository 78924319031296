import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import {
  Disciplines,
  SessionStatusSchema,
  TrainingSession,
  TrainingSessionSchema,
  TrainingSessionSource,
} from "../data/sessions";
import { useCallback, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  fromDateString,
  fromTimeString,
  toDateString,
  toTimeString,
} from "../../../utils/date-utils";
import { Divider } from "primereact/divider";
import { FloatButton } from "../components/FloatButton";
import { CompleteSessionDialog } from "./CompleteSessionDialog";
import { FieldSet } from "../components/FieldSet";
import { set } from "lodash";
import { FieldSetEditor } from "./FieldSetEditor";
import {
  Progression,
  ProgressionStore,
} from "../../progressions/data/progressions";

type SessionEditorProps = {
  id: string;
  onComplete: () => void;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  background-color: #fff;
  min-height: 100%;
  position: relative;
  overflow-y: auto;
`;

export const SessionEditor = ({ id, onComplete }: SessionEditorProps) => {
  const navigate = useNavigate();

  const [progressions, setProgressions] = useState<Progression[]>([]);

  const [session, setSession] = useState<Partial<TrainingSession> | null>(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    ProgressionStore.list().then(setProgressions);
  }, []);

  useEffect(() => {
    if (id === "new") {
      setSession({
        title: "",
        status: "draft",
        date: toDateString(new Date()),
        startTime: toTimeString(new Date()),
        planned: {},
        data: {},
      });
      return;
    } else {
      return TrainingSessionSource.observeOne(id, setSession);
    }
  }, [id]);

  const updateField = async (field: string, value: any, save = false) => {
    const updated = set({ ...session } as object, field, value);
    setSession(updated);
    if (save && id !== "new") {
      await TrainingSessionSource.update(session!.id!, updated);
    }
  };

  const onUpdate = useCallback(async () => {
    if (id === "new") return;
    await TrainingSessionSource.update(session!.id!, session!);
  }, [id, session]);

  const onCreate = useCallback(async () => {
    await TrainingSessionSource.create(
      TrainingSessionSchema.parse(session),
    ).then(() => navigate(`/schedule`));
  }, [session, navigate]);

  if (!session) return null;

  return (
    <Container>
      <FieldSet>
        <h5>Title</h5>
        <InputText
          placeholder="Session title"
          value={session.title}
          onChange={(e) => updateField("title", e.target.value)}
          onBlur={onUpdate}
          autoFocus
        />
        <h5>Status</h5>
        <Dropdown
          options={SessionStatusSchema.options.map((val) => ({ value: val }))}
          optionLabel="value"
          value={session.status}
          onChange={(e) => updateField("status", e.value, true)}
        />

        <h5>Discipline</h5>
        <Dropdown
          options={Disciplines.options.map((val) => ({ value: val }))}
          optionLabel="value"
          value={session.discipline}
          onChange={(e) => updateField("discipline", e.value, true)}
        />
        <h5>Priority</h5>
        <Dropdown
          options={[
            { value: 1, label: "A" },
            { value: 2, label: "B" },
          ]}
          optionLabel="label"
          value={session.priority}
          onChange={(e) => updateField("priority", e.value, true)}
        />

        <h5>Date</h5>
        <Calendar
          value={new Date(fromDateString(session.date) ?? new Date())}
          onChange={(e) => {
            if (e.value) updateField("date", toDateString(e.value), true);
          }}
          showIcon
        />

        <h5>Time</h5>
        <Calendar
          value={session.startTime ? fromTimeString(session.startTime) : null}
          onChange={(e) => {
            updateField(
              "startTime",
              e.value ? toTimeString(e.value) : null,
              true,
            );
          }}
          timeOnly
        />

        {progressions && progressions.length > 0 && (
          <>
            <h5>Progression</h5>
            <Dropdown
              options={progressions.map((p) => ({
                value: p.id,
                label: p.title,
              }))}
              optionLabel="label"
              value={session.progressionId}
              onChange={(e) => updateField("progressionId", e.value, true)}
            />
          </>
        )}
      </FieldSet>

      <Divider />

      <h4>Plan</h4>

      <FieldSetEditor
        key={`plan-${session.id}`}
        discipline={session.discipline!}
        data={session.planned ?? {}}
        onChange={(data) => updateField("planned", data, true)}
      />

      <Divider />

      <h4>Data</h4>

      <FieldSetEditor
        key={`data-${session.id}`}
        discipline={session.discipline!}
        data={session.data ?? {}}
        onChange={(data) => updateField("data", data, true)}
      />

      {id === "new" && (
        <FloatButton
          label="Create"
          disabled={!TrainingSessionSchema.safeParse(session).success}
          loading={saving}
          onClick={() => {
            setSaving(true);
            onCreate().finally(() => setSaving(false));
          }}
        />
      )}
      {id !== "new" && session.status === "planned" && (
        <CompleteSessionDialog
          session={session as TrainingSession}
          onComplete={onComplete}
        />
      )}
    </Container>
  );
};
