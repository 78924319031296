import { RouteLayout } from "../../../layouts/RouteLayout";
import {
  CalendarWeek,
  generateCalendarWeeks,
  Progression,
  ProgressionStore,
  stringDateRange,
} from "../data/progressions";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActionMenu } from "../../../components/ActionMenu";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Title } from "../../../base";
import { Column as TableColumn } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  TrainingSession,
  TrainingSessionSource,
} from "../../schedule/data/sessions";
import moment from "moment";
import { Button } from "primereact/button";
import { StatusTag } from "../../schedule/components/StatusTag";

export const ProgressionRoute = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [progression, setProgression] = useState<Progression | null>(null);
  const [weeks, setWeeks] = useState<CalendarWeek[] | null>(null);

  const [sessions, setSessions] = useState<TrainingSession[] | null>(null);

  useEffect(() => {
    ProgressionStore.find(id!).then(setProgression);
  }, [id]);

  useEffect(() => {
    TrainingSessionSource.listBy([
      { field: "progressionId", comparator: "==", value: id! },
    ]).then(setSessions);
  }, [id]);

  useEffect(() => {
    if (progression) {
      setWeeks(
        generateCalendarWeeks(progression.startDate, progression.durationWeeks),
      );
    }
  }, [progression]);

  const onDelete = () => {
    confirmDialog({
      message:
        "Do you want to delete this progression? This action cannot be undone.",
      header: "Delete Progression",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => {
        ProgressionStore.delete(progression!.id).then(() =>
          navigate("/progressions"),
        );
      },
    });
  };

  const columns = [
    { field: "index", header: "#", width: "45px" },
    {
      field: "week",
      header: "Week",
      width: "160px",
      template: stringDateRange,
    },
    {
      field: "session",
      header: "Session",
      width: "200px",
      type: "text",
      template: (rowData: CalendarWeek) => {
        const session = (sessions ?? []).find((s) =>
          moment(s.date).isBetween(rowData.start, rowData.end, "day", "[]"),
        );

        if (session) return session.title;

        return (
          <Button text severity="secondary" size="small">
            Create session
          </Button>
        );
      },
    },
    {
      field: "sessionStatus",
      header: "Status",
      width: "100px",
      type: "text",
      template: (data: CalendarWeek) => {
        const session = (sessions ?? []).find((s) =>
          moment(s.date).isBetween(data.start, data.end, "day", "[]"),
        );

        if (session) return <StatusTag status={session.status} />;
      },
    },
    {
      field: "progressionMetric",
      header: "Distance",
      width: "100px",
      type: "text",
      template: (data: CalendarWeek) => {
        const session = (sessions ?? []).find((s) =>
          moment(s.date).isBetween(data.start, data.end, "day", "[]"),
        );

        //@ts-ignore
        if (session) return session.data[progression!.progressionMetric];
      },
    },
    {
      field: "supportMetric",
      header: "Pace",
      width: "100px",
      type: "text",
      template: (data: CalendarWeek) => {
        const session = (sessions ?? []).find((s) =>
          moment(s.date).isBetween(data.start, data.end, "day", "[]"),
        );

        //@ts-ignore
        if (session) return session.data[progression!.supportMetric];
      },
    },
    {
      field: "notes",
      header: "Notes",
      type: "text",
      width: "30%",
      template: (data: CalendarWeek) => {
        const session = (sessions ?? []).find((s) =>
          moment(s.date).isBetween(data.start, data.end, "day", "[]"),
        );

        //@ts-ignore
        if (session) return session.data?.description;
      },
    },
  ];

  return (
    <RouteLayout
      header={{
        back: { path: "/progressions" },
        title: progression?.title,
        actions: (
          <ActionMenu
            actions={[
              {
                label: "Delete progression",
                icon: "pi pi-trash",
                command: onDelete,
              },
            ]}
          />
        ),
      }}
    >
      <DataTable
        value={
          (weeks ?? []).map((w, index) => ({ ...w, index: index + 1 })) ?? []
        }
        showGridlines
        tableStyle={{ tableLayout: "fixed", borderRadius: "8px" }}
        scrollable
        scrollHeight="75vh"
      >
        {columns.map(({ field, header, width, type, template }) => {
          return (
            <TableColumn
              key={field}
              field={field}
              header={<Title style={{ padding: "16px" }}>{header}</Title>}
              body={(rowData) => (
                <Title style={{ padding: "16px" }}>
                  {template ? template(rowData) : rowData[field]}
                </Title>
              )}
              style={{ padding: 0, width: width ?? "20%" }}
            />
          );
        })}
      </DataTable>
      <ConfirmDialog />
    </RouteLayout>
  );
};
