import { DeviceAwareLayout } from "../DeviceAwareLayout";
import { MobileHeader } from "./MobileHeader";
import { DesktopHeader } from "./DesktopHeader";
import { ReactNode } from "react";

export type HeaderProps = {
  title?: string;
  actions?: ReactNode;
  back?: { path: string; title?: string };
  variant?: "default" | "transparent";
};

export const Header = (props: HeaderProps) => (
  <DeviceAwareLayout
    web={<DesktopHeader {...props} />}
    mobile={<MobileHeader {...props} />}
  />
);
