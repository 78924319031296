import styled from "styled-components";
import { AppLayoutProps } from "./index";

export const MobileAppLayout = ({ content }: AppLayoutProps) => {
  return (
    <RootLayout>
      <ContentSection>{content}</ContentSection>
    </RootLayout>
  );
};

const RootLayout = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #f8f8f9;
`;

const ContentSection = styled.section`
  flex: 1;
`;
