import styled from "styled-components";
import { Column, SubTitle, Title } from "base";
import { Season } from "../data/seasons";
import moment from "moment";

type SeasonCardProps = {
  season: Season;
  onClick?: () => void;
};

const Root = styled(Column)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background-color: #f8f8f9;
  }
`;

export const SeasonCard = ({ season, onClick }: SeasonCardProps) => {
  return (
    <Root onClick={onClick}>
      <Title>{season.title}</Title>
      <SubTitle>
        {moment(season.start).format("DD.MM.YYYY")} -{" "}
        {moment(season.end).format("DD.MM.YYYY")}
      </SubTitle>
    </Root>
  );
};
