import { createContext, ReactNode, useContext, useRef } from "react";
import { Toast } from "primereact/toast";

type ToastContextDef = {
  success: (summary: string) => void;
  error: (summary: string) => void;
};

export const ToastContext = createContext<ToastContextDef>({
  success: () => {},
  error: () => {},
});

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }: { children: ReactNode }) => {
  const toast = useRef<Toast | null>(null);

  const defaults = {
    life: 3000,
  };

  const success = (summary: string) => {
    if (!toast.current) return;

    toast.current.show({
      ...defaults,
      severity: "success",
      summary,
    });
  };

  const error = (summary: string) => {
    if (!toast.current) return;

    toast.current.show({
      ...defaults,
      severity: "error",
      summary,
    });
  };

  return (
    <ToastContext.Provider value={{ success, error }}>
      <Toast ref={toast} position="bottom-center" />
      {children}
    </ToastContext.Provider>
  );
};
