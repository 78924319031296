import { z } from "zod";
import { uuid } from "../../../business/data/utils";
import { DateStringSchema } from "../../../utils/date-utils";
import { FirestoreWorkspaceEntitySource } from "../../../business";
import { getCurrentWorkspace } from "../../../business/auth";

export const DailyReviewSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  date: DateStringSchema,
  score: z.number().int(),
  notes: z.string(),
});
export type DailyReview = z.infer<typeof DailyReviewSchema>;

export const DailyReviewStore = new FirestoreWorkspaceEntitySource<DailyReview>(
  "dailyReviews",
  DailyReviewSchema,
  getCurrentWorkspace,
);
