import { NotesListRoute } from "./routes/NotesListRoute";
import { NoteEditRoute } from "./routes/NoteEditRoute";

export const NotesRoutes = [
  {
    path: "notes",
    element: <NotesListRoute />,
  },
  {
    path: "notes/:id",
    element: <NoteEditRoute />,
  },
];
