import { TrainingSession } from "../data/sessions";
import { DataView } from "primereact/dataview";
import { SessionCard } from "../components/SessionCard";
import moment from "moment/moment";
import { DateString, fromDateString } from "../../../utils/date-utils";
import { useMemo } from "react";
import { Panel } from "primereact/panel";
import { buildWeekString } from "../components/WeekTitle";
import { Button } from "primereact/button";
import { Column } from "../../../base";
import { useNavigate } from "react-router-dom";

type WeekScheduleProps = {
  sessions: TrainingSession[];
  weekStart: DateString;
  activeSession?: string;
  onSessionClick?: (session: TrainingSession) => void;
  onWeekClick?: (weekStart: DateString) => void;
};
export const WeekSchedule = ({
  sessions,
  weekStart,
  activeSession,
  onSessionClick,
  onWeekClick,
}: WeekScheduleProps) => {
  const navigate = useNavigate();

  const weeklySessions = useMemo(() => {
    return sessions.filter((s) =>
      moment(fromDateString(s.date)).isSame(moment(weekStart), "isoWeek"),
    );
  }, [sessions, weekStart]);

  const isActive = moment().isSameOrBefore(weekStart, "isoWeek");

  return (
    <Panel
      header={
        <div
          onClick={() => onWeekClick?.(weekStart)}
          style={{ cursor: "pointer" }}
        >
          {buildWeekString(weekStart)}
        </div>
      }
      toggleable
      collapsed={!moment().isSame(weekStart, "isoWeek")}
    >
      <Column>
        <DataView
          value={weeklySessions}
          itemTemplate={(s) => (
            <SessionCard
              key={s.id}
              session={s}
              onClick={() => onSessionClick?.(s)}
              active={s.id === activeSession}
            />
          )}
        />

        {isActive && (
          <Button
            text
            style={{ margin: "1rem auto" }}
            onClick={() => navigate("/schedule/session/new")}
          >
            Add session
          </Button>
        )}
      </Column>
    </Panel>
  );
};
