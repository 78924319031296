import { Button } from "primereact/button";
import { RouteLayout } from "../../../layouts/RouteLayout";
import { useNavigate } from "react-router-dom";
import { DataView } from "primereact/dataview";
import { LoadingList } from "../../../components";
import { ProgressionCard } from "../components/ProgressionCard";
import { useEffect, useState } from "react";
import { IntroLayout } from "../../../layouts/IntroLayout";
import { Column } from "../../../base";
import { Progression, ProgressionStore } from "../data/progressions";
import { CreateProgressionModal } from "../containers/CreateProgressionModal";

export const ProgressionListRoute = () => {
  const navigate = useNavigate();

  const [createOpen, setCreateOpen] = useState(false);

  const [progressions, setProgressions] = useState<Progression[] | undefined>();

  useEffect(() => {
    ProgressionStore.list().then(setProgressions);
  }, [navigate]);

  return (
    <RouteLayout
      header={{
        title: "Progressions",
        actions: (
          <Button
            size="small"
            text
            icon="pi pi-plus"
            onClick={() => setCreateOpen(true)}
            severity="secondary"
          />
        ),
      }}
    >
      {progressions && progressions.length === 0 && (
        <IntroLayout
          title="The bread and butter of effective training"
          description="Plan and track 3 - 10 week long blocks aimed to progress your performance in a specific area."
          action={
            <Column>
              <Button onClick={() => setCreateOpen(true)}>
                Create progression
              </Button>
              <a href="/">Read why you should use progressions</a>
            </Column>
          }
        />
      )}
      {progressions && progressions.length > 0 && (
        <DataView
          value={progressions}
          itemTemplate={(progression) => (
            <ProgressionCard
              progression={progression}
              onClick={() => navigate(`/progressions/${progression.id}`)}
            />
          )}
        />
      )}
      {!progressions && <LoadingList rows={6} rowHeight="60px" />}
      <CreateProgressionModal
        open={createOpen}
        onClose={() => setCreateOpen(false)}
        onCreated={(progression) => navigate(`/progressions/${progression.id}`)}
      />
    </RouteLayout>
  );
};
