import styled from "styled-components";
import { HeaderProps } from "./index";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { AppMenu } from "../AppMenu";

const Header = styled.div<{ $transparent?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 12px;
  background: ${({ $transparent }) => ($transparent ? "transparent" : "#fff")};
`;

export const MobileHeader = ({
  title,
  actions,
  back,
  variant,
}: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Header $transparent={variant === "transparent"}>
      {back && (
        <Button
          text
          icon="pi pi-angle-left"
          severity="secondary"
          size="small"
          onClick={() => navigate(back.path)}
        >
          {back.title}
        </Button>
      )}
      {!back && <AppMenu popup />}
      {title && <h4 style={{ textAlign: "center" }}>{title}</h4>}
      <div>{actions}</div>
    </Header>
  );
};
