import { DailyCheck } from "../data/dailychecks";
import { Card } from "primereact/card";
import { Row } from "../../../base";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";

const CheckCard = styled(Card)`
  .p-card-content {
    padding: 0;
  }
  .p-card-body {
    padding: 8px 1rem;
  }
`;

type DailyCheckItemProps = {
  check: DailyCheck;
  onChanged: (check: DailyCheck) => void;
  onRemove: () => void;
};

export const DailyCheckItem = ({
  check,
  onChanged,
  onRemove,
}: DailyCheckItemProps) => {
  return (
    <CheckCard>
      <Row
        style={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Row gap="large" style={{ flex: 1 }}>
          <Checkbox
            onChange={(e) => onChanged({ ...check, completed: !!e.checked })}
            checked={check.completed}
          ></Checkbox>
          <InputText
            style={{
              outline: "none",
              border: "none",
              fontWeight: "600",
              width: "100%",
            }}
            defaultValue={check.title}
            onBlur={(e) => onChanged({ ...check, title: e.target.value })}
          />
        </Row>

        <Button text icon="pi pi-times" onClick={onRemove} />
      </Row>
    </CheckCard>
  );
};
