import styled from "styled-components";
import { Column, SubTitle, Title } from "base";
import moment from "moment";
import { Progression } from "../data/progressions";
import { useMemo } from "react";

type ProgressionCardProps = {
  progression: Progression;
  onClick?: () => void;
};

const Root = styled(Column)`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background-color: #f8f8f9;
  }
`;

export const ProgressionCard = ({
  progression,
  onClick,
}: ProgressionCardProps) => {
  const end = useMemo(() => {
    return moment(progression.startDate)
      .add(progression.durationWeeks, "weeks")
      .subtract(1, "days")
      .format("L");
  }, [progression]);

  return (
    <Root onClick={onClick}>
      <Title>{progression.title}</Title>
      <SubTitle>
        {moment(progression.startDate).format("L")} - {end}
      </SubTitle>
    </Root>
  );
};
