import { Button } from "primereact/button";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Panel } from "primereact/panel";
import { Row, Title } from "../../../base";
import { Checkbox } from "primereact/checkbox";
import { FieldSet } from "../../schedule/components/FieldSet";
import { InputText } from "primereact/inputtext";
import {
  DailyCheckSetting,
  DailyCheckSettingSchema,
  DailyCheckSettingStore,
} from "../data/dailychecks";
import { uuid } from "../../../business/data/utils";

export const DailyCheckSettings = () => {
  const [open, setOpen] = useState(false);

  const [settings, setSettings] = useState<DailyCheckSetting[] | undefined>();

  useEffect(() => DailyCheckSettingStore.observe(setSettings), []);

  const logSetting = useMemo(() => {
    const setting = settings?.find((s) => s.key === "logCompleted");
    return (
      setting ?? {
        key: "logCompleted",
        active: false,
        title: "Daily checks",
        duration: 15,
      }
    );
  }, [settings]);

  const updateSetting = useCallback((setting: Partial<DailyCheckSetting>) => {
    const { id, ...updates } = setting;

    if (id) {
      DailyCheckSettingStore.update(id, updates);
    } else {
      DailyCheckSettingStore.create(
        DailyCheckSettingSchema.parse({ ...updates, id: uuid() }),
      );
    }
  }, []);

  return (
    <>
      <Button text icon="pi pi-cog" onClick={() => setOpen(true)} />
      <Dialog
        header="Daily checks"
        onHide={() => setOpen(false)}
        visible={open}
        style={{ width: "500px" }}
      >
        <Panel
          toggleable
          header={
            <Row>
              <Checkbox
                checked={logSetting.active}
                onChange={(e) =>
                  updateSetting({ ...logSetting, active: !!e.checked })
                }
              />
              <h4>Log a training session when all checks completed</h4>
            </Row>
          }
        >
          <FieldSet $ratio={3}>
            <Title>Title</Title>
            <InputText
              defaultValue={logSetting.title}
              onBlur={(e) =>
                updateSetting({ ...logSetting, title: e.target.value })
              }
            />
            <Title>Duration</Title>
            <div
              className="p-inputgroup"
              style={{ width: "150px", height: "45px" }}
            >
              <InputText
                type="number"
                defaultValue={logSetting.duration ?? ""}
                onBlur={(e) =>
                  updateSetting({
                    ...logSetting,
                    duration: parseInt(e.target.value),
                  })
                }
              />
              <span className="p-inputgroup-addon">
                <p>min</p>
              </span>
            </div>
          </FieldSet>
        </Panel>
      </Dialog>
    </>
  );
};
