import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  User,
} from "firebase/auth";

export type ZoneUser = User;

export const getCurrentWorkspace = (): string => {
  const user = getCurrentUser();
  if (!user) throw new Error("No user found");
  return user.uid;
};

export const getCurrentUser = (): ZoneUser | null => {
  const auth = getAuth();
  return auth.currentUser;
};

export const signInWithGoogle = async () => {
  const auth = getAuth();
  const result = await signInWithPopup(auth, new GoogleAuthProvider());
  return result.user;
};
export const waitForUserLoading = (): Promise<ZoneUser | null> => {
  const auth = getAuth();
  return new Promise((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      unsubscribe();
      resolve(getCurrentUser());
    });
  });
};

export const logout = () => {
  const auth = getAuth();
  return auth.signOut();
};

export const getIDToken = (): Promise<string | undefined> => {
  const auth = getAuth();

  if (!auth.currentUser) return Promise.resolve(undefined);
  return auth.currentUser!.getIdToken();
};
