import { Note } from "../data/notes";
import styled from "styled-components";
import { SubTitle, Title } from "../../../base";

type NoteCardProps = {
  note: Note;
  onClick?: () => void;
};

const Root = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 1rem;

  &:hover {
    background-color: #f8f8f9;
  }
`;

export const NoteCard = ({ note, onClick }: NoteCardProps) => {
  const resolveDate = () => {
    if (note.editedAt.toDateString() === new Date().toDateString()) {
      return "today";
    }
    const dateStr = note.editedAt.toDateString();
    return dateStr.substring(0, dateStr.length - 4);
  };

  return (
    <Root onClick={onClick}>
      <Title>{note.title}</Title>
      <SubTitle style={{ width: "70px", textAlign: "right" }}>
        {resolveDate()}
      </SubTitle>
    </Root>
  );
};
