import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Column, Title } from "base";
import { FieldSet } from "../../schedule/components/FieldSet";
import cx from "classnames";

import {
  Progression,
  ProgressionSchema,
  ProgressionStore,
} from "../data/progressions";
import { InputTextarea } from "primereact/inputtextarea";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { DisciplineSelect } from "../../sessions/components/DisciplineSelect";
import { ProgressionMetrics, SupportMetrics } from "../data/metrics";
import { Dropdown } from "primereact/dropdown";
import { toDateString } from "../../../utils/date-utils";
import { useToast } from "../../../hooks/use-toast";

type CreateProgressionModalProps = {
  open: boolean;
  onClose: () => void;
  onCreated: (progression: Progression) => void;
};

export const CreateProgressionModal = ({
  open,
  onClose,
  onCreated,
}: CreateProgressionModalProps) => {
  const toast = useToast();

  const {
    register,
    control,
    handleSubmit,

    formState: { errors },
  } = useForm<Progression>({
    resolver: zodResolver(ProgressionSchema),
  });

  const onCreate = async (data: Progression) => {
    ProgressionStore.create(data)
      .then(onCreated)
      .catch(() => toast.error("Failed to create progression"));
  };

  return (
    <Dialog
      header="Create progression"
      onHide={onClose}
      visible={open}
      footer={<Button onClick={handleSubmit(onCreate)}>Create</Button>}
    >
      <form>
        <Column>
          <FieldSet>
            <Title>Title</Title>
            <Column>
              <InputText
                className={cx({ "p-invalid": errors.title })}
                placeholder="Progression title"
                {...register("title")}
              />
            </Column>
            <Title>Description</Title>
            <InputTextarea
              placeholder="Description"
              {...register("description")}
              className={cx({ "p-invalid": errors.description })}
            />
            <Title>Discipline</Title>
            <Controller
              name="discipline"
              control={control}
              render={({ field }) => (
                <DisciplineSelect
                  id={field.name}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  className={cx({ "p-invalid": errors.discipline })}
                />
              )}
            />
            <Title>Progression metric</Title>
            <Controller
              name="progressionMetric"
              control={control}
              render={({ field }) => (
                <Dropdown
                  options={ProgressionMetrics}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  className={cx({ "p-invalid": errors.progressionMetric })}
                />
              )}
            />
            <Title>Support metric</Title>
            <Controller
              name="supportMetric"
              control={control}
              render={({ field }) => (
                <Dropdown
                  options={SupportMetrics}
                  value={field.value}
                  onChange={(e) => field.onChange(e.value)}
                  className={cx({ "p-invalid": errors.supportMetric })}
                />
              )}
            />
            <Title>Start</Title>
            <Controller
              name="startDate"
              control={control}
              render={({ field }) => (
                <Calendar
                  showIcon
                  value={field.value ? new Date(field.value) : undefined}
                  formatDateTime={(e) => e.toLocaleDateString()}
                  onChange={(e) => field.onChange(toDateString(e.value!))}
                  className={cx({ "p-invalid": errors.startDate })}
                />
              )}
            />

            <Title>Duration</Title>
            <div
              className="p-inputgroup"
              style={{ width: "150px", height: "45px" }}
            >
              <Controller
                name="durationWeeks"
                control={control}
                render={({ field }) => (
                  <InputText
                    type="number"
                    value={field.value ? field.value.toString() : ""}
                    onChange={(e) => field.onChange(parseInt(e.target.value))}
                    className={cx({ "p-invalid": errors.durationWeeks })}
                  />
                )}
              />

              <span className="p-inputgroup-addon">
                <p>weeks</p>
              </span>
            </div>
          </FieldSet>
        </Column>
      </form>
    </Dialog>
  );
};
