import { Menu } from "primereact/menu";
import { useMemo, useRef, useState } from "react";
import { MenuItem } from "primereact/menuitem";
import { Button } from "primereact/button";
import styled from "styled-components";

type LoadingMenuItem = MenuItem & {
  asyncCommand?: () => Promise<void>;
};

type ActionMenuProps = {
  actions: LoadingMenuItem[];
  loading?: boolean;
};

const ActionButton = styled(Button)`
  &:enabled:focus {
    box-shadow: none;
  }
`;

export const ActionMenu = ({ actions }: ActionMenuProps) => {
  const menuRef = useRef<Menu>(null);
  const [loading, setLoading] = useState(false);

  const mappedActions = useMemo(() => {
    return actions.map((a) => ({
      ...a,
      command: a.asyncCommand
        ? () => {
            setLoading(true);
            a.asyncCommand!().finally(() => {
              setLoading(false);
            });
          }
        : a.command,
    }));
  }, [actions]);

  return (
    <>
      <Menu
        model={mappedActions}
        popup
        ref={menuRef}
        id="action_menu"
        popupAlignment="right"
      />
      <ActionButton
        size="small"
        severity="secondary"
        loading={loading}
        icon="pi pi-ellipsis-h"
        text
        aria-label="Actions"
        onClick={(event) => menuRef.current?.toggle(event)}
        aria-controls="action_menu"
        aria-haspopup
      />
    </>
  );
};
