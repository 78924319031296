import { ReactNode } from "react";
import { DeviceAwareLayout } from "../DeviceAwareLayout";
import { DesktopAppLayout } from "./DesktopAppLayout";
import { MobileAppLayout } from "./MobileAppLayout";

export * from "./DesktopAppLayout";

export type AppLayoutProps = {
  menu: ReactNode;
  content: ReactNode;
  header?: ReactNode;
};

export const AppLayout = (props: AppLayoutProps) => (
  <DeviceAwareLayout
    web={<DesktopAppLayout {...props} />}
    mobile={<MobileAppLayout {...props} />}
  />
);
