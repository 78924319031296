import { ScheduleRoute } from "./routes/ScheduleRoute";

export const ScheduleRoutes = [
  {
    path: "schedule",
    element: <ScheduleRoute />,
  },
  {
    path: "schedule/:section/:id",
    element: <ScheduleRoute />,
  },
];
