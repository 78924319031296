import { TodayRoute } from "./TodayRoute";

export const TodayRoutes = [
  {
    path: "today",
    element: <TodayRoute />,
  },
  {
    path: "today/session/:sessionId",
    element: <TodayRoute />,
  },
];
