import Swim from "./swim.png";
import Bike from "./bike.png";
import Run from "./run.png";
import { Discipline, Disciplines } from "../modules/schedule/data/sessions";

export const SwimIcon = () => (
  <img src={Swim} alt="swim" style={{ width: "24px", height: "24px" }} />
);

export const BikeIcon = () => (
  <img src={Bike} alt="bike" style={{ width: "24px", height: "24px" }} />
);

export const RunIcon = () => (
  <img src={Run} alt="run" style={{ width: "24px", height: "24px" }} />
);

export const DisciplineIcon = ({ discipline }: { discipline: Discipline }) => {
  switch (discipline) {
    case Disciplines.Values.swim:
      return <SwimIcon />;
    case Disciplines.Values.bike:
      return <BikeIcon />;
    case Disciplines.Values.run:
      return <RunIcon />;
    default:
      return <div style={{ width: "24px", height: "24px" }} />;
  }
};
