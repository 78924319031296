import { TrainingSession } from "../data/sessions";
import { ComponentProps } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingList } from "../../../components";
import { groupBy } from "lodash";
import { fromDateString } from "../../../utils/date-utils";
import moment from "moment";
import { WeekSchedule } from "../containers/WeekSchedule";
import { Column } from "../../../base";

type SessionListProps = ComponentProps<"div"> & {
  sessions?: TrainingSession[];
  active?: string;
};

export const SessionList = ({
  sessions,
  active,
  ...props
}: SessionListProps) => {
  const navigate = useNavigate();

  const byWeek = groupBy(sessions ?? [], (s) =>
    moment(fromDateString(s.date)).startOf("isoWeek").format("YYYY-MM-DD"),
  );

  return (
    <Column gap="large" {...props}>
      {!sessions && <LoadingList rows={6} />}
      {sessions &&
        Object.keys(byWeek).map((week, i) => (
          <WeekSchedule
            key={week}
            weekStart={week}
            sessions={byWeek[week]}
            activeSession={active}
            onSessionClick={(s) => navigate(`/schedule/session/${s.id}`)}
            onWeekClick={(w) => navigate(`/schedule/week/${w}`)}
          />
        ))}
    </Column>
  );
};
