import styled from "styled-components";

export const Title = styled.h5`
  margin: 0;
`;

export const SubTitle = styled.p`
  font-size: 11px;
  margin: 0;
`;
