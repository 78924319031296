import { z } from "zod";
import { FirestoreWorkspaceEntitySource } from "../../../business";
import { getCurrentWorkspace } from "../../../business/auth";
import { uuid } from "../../../business/data/utils";
import { DateStringSchema, toDateString } from "../../../utils/date-utils";
import moment from "moment";
import { TrainingSessionSource } from "../../schedule/data/sessions";

export const DailyCheckSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  date: DateStringSchema.default(() => toDateString(new Date())),
  title: z.string(),
  completed: z.boolean().default(false),
});
export type DailyCheck = z.infer<typeof DailyCheckSchema>;

export const DailyCheckStore = new FirestoreWorkspaceEntitySource<DailyCheck>(
  "dailyChecks",
  DailyCheckSchema,
  getCurrentWorkspace,
);

export const DailyCheckSettingSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  key: z.string(),
  active: z.boolean().default(false),
  title: z.string(),
  duration: z.number().nullish(),
});
export type DailyCheckSetting = z.infer<typeof DailyCheckSettingSchema>;

export const DailyCheckSettingStore =
  new FirestoreWorkspaceEntitySource<DailyCheckSetting>(
    "dailyCheckSettings",
    DailyCheckSettingSchema,
    getCurrentWorkspace,
  );

export const initTodayChecks = async () => {
  const checks = await DailyCheckStore.list([
    { field: "date", direction: "desc" },
  ]);

  const latestDate = checks.length > 0 ? checks[0].date : null;

  if (moment(latestDate).isBefore(moment(), "day")) {
    const latestDayChecks = checks.filter((c) => c.date === latestDate);
    for (const latestDayCheck of latestDayChecks) {
      await DailyCheckStore.create(
        DailyCheckSchema.parse({
          date: toDateString(new Date()),
          title: latestDayCheck.title,
          completed: false,
        }),
      );
    }
  }
};

export const checkAllCompleted = async () => {
  const settings = await DailyCheckSettingStore.list();
  const logSetting = settings.find((s) => s.key === "logCompleted");

  if (!logSetting || !logSetting.active) return;

  const checks = await DailyCheckStore.listBy([
    { field: "date", comparator: "==", value: toDateString(new Date()) },
  ]);
  const allCompleted = checks.every((c) => c.completed);

  if (!allCompleted) return;

  const sessions = await TrainingSessionSource.listBy([
    { field: "date", comparator: "==", value: toDateString(new Date()) },
  ]);

  const hasCheckSession = sessions.some((s) => s.title === logSetting.title);

  if (!hasCheckSession) {
    await TrainingSessionSource.create({
      date: toDateString(new Date()),
      startTime: null,
      title: logSetting.title,
      discipline: "other",
      status: "completed",
      planned: {},
      data: {
        duration: logSetting.duration,
      },
    });
  }
};
