import { z } from "zod";
import { FirestoreWorkspaceEntitySource } from "../../../business";
import { getCurrentWorkspace } from "../../../business/auth";

export const NoteSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  editedAt: z
    .date()
    .or(z.string())
    .transform((v) => new Date(v))
    .default(() => new Date()),
  content: z.unknown().nullable().default(null),
});
export type Note = z.infer<typeof NoteSchema>;

export const NotesSource = new FirestoreWorkspaceEntitySource<Note>(
  "notes",
  NoteSchema,
  getCurrentWorkspace,
);
