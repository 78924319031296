import { initializeApp } from "firebase/app";
import { migrate } from "./migrate";

const firebaseConfig = {
  apiKey: "AIzaSyAxm5xq73WyH7psnTRJ4OEfmQb7wBI_LZI",
  authDomain: "zone-web-poc.firebaseapp.com",
  projectId: "zone-web-poc",
  storageBucket: "zone-web-poc.appspot.com",
  messagingSenderId: "605889266993",
  appId: "1:605889266993:web:857208e455e5fecfa05c10",
};

export const firebaseApp = initializeApp(firebaseConfig);

migrate().then(() => {});
