import { ProgressionListRoute } from "./routes/ProgressionListRoute";
import { ProgressionRoute } from "./routes/ProgressionRoute";

export const ProgressionRoutes = [
  {
    path: "progressions",
    element: <ProgressionListRoute />,
  },
  {
    path: "progressions/:id",
    element: <ProgressionRoute />,
  },
];
