import { z } from "zod";
import { uuid } from "../../../business/data/utils";
import {
  DateString,
  DateStringSchema,
  toDateString,
} from "../../../utils/date-utils";
import { FirestoreWorkspaceEntitySource } from "../../../business";
import { getCurrentWorkspace } from "../../../business/auth";
import { Disciplines } from "../../schedule/data/sessions";
import moment from "moment";

export const ProgressionSchema = z.object({
  id: z
    .string()
    .uuid()
    .default(() => uuid()),
  title: z.string().min(1),
  description: z.string(),
  discipline: Disciplines,
  progressionMetric: z.string().min(1),
  supportMetric: z.string().nullish(),
  startDate: DateStringSchema,
  durationWeeks: z.number(),
});
export type Progression = z.infer<typeof ProgressionSchema>;

export const ProgressionStore = new FirestoreWorkspaceEntitySource<Progression>(
  "progressions",
  ProgressionSchema,
  getCurrentWorkspace,
);

export const CalendarWeekSchema = z.object({
  weekNumber: z.number(),
  start: DateStringSchema,
  end: DateStringSchema,
});

export type CalendarWeek = z.infer<typeof CalendarWeekSchema>;

export const generateCalendarWeeks = (
  startDate: DateString,
  durationWeeks: number,
): CalendarWeek[] => {
  const start = new Date(startDate);
  const weeks: CalendarWeek[] = [];

  for (let i = 0; i < durationWeeks; i++) {
    const weekStart = new Date(start);
    weekStart.setDate(weekStart.getDate() + i * 7);

    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6);

    weeks.push({
      weekNumber: i + 1,
      start: toDateString(weekStart),
      end: toDateString(weekEnd),
    });
  }
  return weeks;
};

export const stringDateRange = (week: CalendarWeek) =>
  `${moment(week.start).format("L")} - ${moment(week.end).format("L")}`;
