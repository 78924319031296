import { DateString } from "../../../utils/date-utils";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Discipline, TrainingSession, TrainingSessionSource } from "./sessions";
import { sumBy } from "lodash";
import { SessionFields } from "./fields";

export type SummarySection = Discipline | "total";

export type CategorisedSummary = {
  planned: { [index: string]: number };
  completed: { [index: string]: number };
};

export type FieldSummary = {
  planned: number;
  completed: number;
};

export type SummaryData = {
  total: CategorisedSummary;
  swim: CategorisedSummary;
  bike: CategorisedSummary;
  run: CategorisedSummary;
  strength: CategorisedSummary;
  other: CategorisedSummary;
};

export interface WeekSummary {
  title: string;
  data: SummaryData;
  get: (discipline: SummarySection, field: string) => FieldSummary;
}

export const useWeekSummary = (date: DateString) => {
  const [sessions, setSessions] = useState<TrainingSession[] | undefined>();

  useEffect(() => {
    TrainingSessionSource.list().then(setSessions);
  }, []);

  const { start, end } = useMemo(() => {
    return {
      start: moment(date).startOf("isoWeek"),
      end: moment(date).endOf("isoWeek"),
    };
  }, [date]);

  const weekSessions = useMemo(() => {
    return (sessions ?? []).filter((s) =>
      moment(s.date).isBetween(start, end, "day", "[]"),
    );
  }, [sessions, start, end]);

  const data: SummaryData = {
    total: calculateCategorisedSummary(weekSessions),
    swim: calculateCategorisedSummary(
      weekSessions.filter((s) => s.discipline === "swim"),
    ),
    bike: calculateCategorisedSummary(
      weekSessions.filter((s) => s.discipline === "bike"),
    ),
    run: calculateCategorisedSummary(
      weekSessions.filter((s) => s.discipline === "run"),
    ),
    strength: calculateCategorisedSummary(
      weekSessions.filter((s) => s.discipline === "strength"),
    ),
    other: calculateCategorisedSummary(
      weekSessions.filter((s) => s.discipline === "other"),
    ),
  };

  return {
    title: `Week ${start.format("W")} (${start.format("MMM DD")} - ${end.format(
      "MMM DD",
    )})`,
    data,
    get: (discipline: Discipline, field: string): FieldSummary => {
      return {
        planned: data[discipline].planned[field] ?? 0,
        completed: data[discipline].completed[field] ?? 0,
      };
    },
  } as WeekSummary;
};

const calculateCategorisedSummary = (sessions: TrainingSession[]) => {
  const planned = sessions.filter((s) =>
    ["draft", "planned", "completed"].includes(s.status),
  );
  const completed = sessions.filter((s) => s.status === "completed");

  return {
    planned: calculateSummary(planned, "planned"),
    completed: calculateSummary(completed, "data"),
  };
};

const calculateSummary = (
  sessions: TrainingSession[],
  category: "planned" | "data",
) => {
  const resolveNumericFieldValue = (
    session: TrainingSession,
    field: keyof SessionFields,
  ): number => {
    if (session.data[field]) return session.data[field] as number;
    if (session.planned[field]) return session.planned[field] as number;
    return 0;
  };

  return {
    count: sessions.length,
    duration: sumBy(sessions, (s) => resolveNumericFieldValue(s, "duration")),
    distance: sumBy(sessions, (s) => resolveNumericFieldValue(s, "distance")),
  };
};
