export const formatMinutes = (minutes: number) => {
  const hours = Math.abs(Math.floor(minutes / 60));
  const remainingMinutes = Math.abs(minutes % 60);

  const hoursString = hours !== 0 ? `${hours}h ` : "";

  return `${minutes < 0 ? "-" : ""}${hoursString}${remainingMinutes}m`;
};

export const toHours = (minutes: number) => {
  const hours = minutes / 60;
  return Math.round(hours * 2) / 2;
};
