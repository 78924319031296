import React, { useEffect } from "react";

import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/mira/theme.css";
import "primeicons/primeicons.css";

import { PrimeReactProvider } from "primereact/api";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { NotesRoutes } from "./modules/notes";
import { Login } from "./Login";
import { AppWrapper } from "./AppWrapper";
import { ScheduleRoutes } from "./modules/schedule";
import { SeasonRoutes } from "./modules/seasons";
import { ToastProvider } from "./hooks/use-toast";
import { TodayRoutes } from "./modules/today";
import { RpcProvider } from "./RpcApi";
import { AskAiRoutes } from "./modules/askai";
import { ProgressionRoutes } from "./modules/progressions";

const Redirect = ({ to }: { to: string }) => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
  }, [to, navigate]);

  return null;
};

function App() {
  const appRouter = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <Redirect to="/today" />,
    },
    {
      path: "*",
      element: <AppWrapper />,
      children: [
        ...ScheduleRoutes,
        ...SeasonRoutes,
        ...NotesRoutes,
        ...TodayRoutes,
        ...AskAiRoutes,
        ...ProgressionRoutes,
      ],
    },
  ]);

  return (
    <RpcProvider>
      <PrimeReactProvider>
        <ToastProvider>
          <RouterProvider router={appRouter} />
        </ToastProvider>
      </PrimeReactProvider>
    </RpcProvider>
  );
}

export default App;
