import { Disciplines } from "../../schedule/data/sessions";
import { Dropdown, DropdownProps } from "primereact/dropdown";
import { forwardRef } from "react";

export const DisciplineSelect = forwardRef<Dropdown, DropdownProps>(
  (props: DropdownProps, ref) => (
    <Dropdown
      ref={ref}
      options={Disciplines.options.map((val) => ({ value: val }))}
      optionLabel="value"
      {...props}
    />
  ),
);
