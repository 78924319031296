import {
  createTRPCReact,
  httpBatchLink,
  httpLink,
  splitLink,
} from "@trpc/react-query";
import { ComponentProps, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AppRouter } from "@zoneplatform/api-types";
import { getIDToken } from "./business/auth";

export const api = createTRPCReact<AppRouter>();

const authHeaders = async () => {
  const token = await getIDToken();
  return {
    authorization: `Bearer ${token}`,
  };
};

export const RpcProvider = (props: ComponentProps<"div">) => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60,
            retry: false,
          },
        },
      }),
  );
  const [trpcClient] = useState(() =>
    api.createClient({
      links: [
        splitLink({
          condition(op) {
            return op.context?.skipBatch === true;
          },
          false: httpBatchLink({
            url: `https://api.zoneplatform.io`,
            headers: authHeaders,
          }),
          true: httpLink({
            url: `https://api.zoneplatform.io`,
            headers: authHeaders,
          }),
        }),
      ],
    }),
  );
  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </api.Provider>
  );
};
