import { SeasonListRoute } from "./routes/SeasonListRoute";
import { SeasonRoute } from "./routes/SeasonRoute";

export const SeasonRoutes = [
  {
    path: "seasons",
    element: <SeasonListRoute />,
  },
  {
    path: "seasons/:id",
    element: <SeasonRoute />,
  },
];
